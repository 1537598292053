export class StripeCustomerModel {
	constructor(
		public address: { city: string; country: string; line1: string; line2?: string; postal_code: string; state: string },
		public balance: number,
		public created: number,
		public currency: string,
		public default_currency: string,
		public delinquent: boolean,
		public description: string,
		public discount,
		public email: string,
		public id: string,
		public invoice_prefix: string,
		public invoice_settings: { custom_fields?: any; default_payment_method?: any; footer?: string; rendering_options?: any },
		public livemode: boolean,
		public metadata: any,
		public name: string,
		public next_invoice_sequence: number,
		public object: string,
		public phone: string,
		public preferred_locales: string[],
		public shipping: any,
		public tax_exempt: string,
		public test_clock: any,
	) {}
}