import { EntityModel, StripeInvoice, StripeProductPriceModel, StripeSubscriptionModel } from '../models';
import { ItemResponseData } from './response-data.interfaces';

// Stripe subscription item response with entity
export interface StripeEntitySubscriptionResponse extends ItemResponseData<StripeSubscriptionModel> {
	entity: EntityModel;
	message?: string;
}

export interface StripeInvoiceLineItem {
	amount: number;
	description: string;
}

export interface StripeInvoiceHistoryResponse {
	totalCount: number;
	hasMore: boolean;
	items: StripeInvoice[];
}

export interface StripeInvoicePreview {
	currentActiveProjectCount: number;
	currentActiveUserCount: number;
	items: StripeInvoiceLineItem[];
	total: number;
	userLimit: number;
	projectLimit: number;
}

export interface StripeSubscriptionAddons {
	price?: StripeProductPriceModel;
	quantity: number;
	stripePriceId: string;
}

export interface StripeSubscriptionUpdate {
	additionalProjectCount?: number;
	additionalUserCount: number;
	shouldCancelAtPeriodEnd: boolean;
	subscriptionPriceId: string;
}
