import { Pipe, PipeTransform } from '@angular/core';
import { MeasureSystemService } from '../services/measure-system.service';

@Pipe({
	name: 'rate',
	pure: false,
	standalone: true,
})
export class RatePipe implements PipeTransform {

	constructor(private measureSystemService: MeasureSystemService) {}

	/**
	 * Lookup table for converting any area unit to square metres.
	 * Values represent how many m² are in one of these units.
	 */
	private readonly lookup: { [unit: string]: number } = {
		mm: 0.001,
		cm: 0.01,
		m: 1,
		lm: 1,
		km: 1000,

		// Imperial/US
		in: 0.0254,
		ft: 0.3048,
		lft: 0.3048,
		yd: 0.9144,
		mi: 1609.344,

		// Metric
		sqmm: 1e-6,
		sqcm: 1e-4,
		sqm: 1,
		sqkm: 1e6,
		are: 100,
		hectare: 10000,

		// Imperial/US
		sqin: 0.00064516,
		sqft: 0.092903,
		sqyd: 0.83612736,
		acre: 4046.8564224,
		sqmi: 2.59e6
	};

	/**
	 * Transforms a cost-per-area value (e.g., $/m²) to another cost-per-area unit (e.g., $/ft²).
	 *
	 * @param value  The numeric cost in terms of (currency / fromUnit). Ex: 10 means "$10 per m²".
	 * @param fromUnit  The original area unit in which `value` is expressed. Ex: "sqm".
	 * @param toMetric  The metric area unit to use if the user/system is set to metric. Ex: "sqm".
	 * @param toImperial The imperial area unit to use if the user/system is set to imperial. Ex: "sqft".
	 * @param system    Optional override for measure system: 'metric' or 'imperial'. If omitted, we use the user’s preference.
	 *
	 * @return The converted cost in $/(target area unit).
	 */
	transform(value: number,
			  fromUnit: string,
			  toMetric: string,
			  toImperial: string,
			  system?: string): number {

		if (typeof value !== 'number' || isNaN(value)) {
			return 0;
		}
		if (value === 0) {
			return 0;
		}

		// Determine which system to output: metric vs. imperial
		if (!system) {
			system = this.measureSystemService.preferredSystem.value; // e.g. 'imperial' or 'metric'
		}

		// Decide which "to unit" we actually want based on user/system preference
		const toUnit = system === 'imperial' ? toImperial : toMetric;

		// If no valid fromUnit/toUnit is provided or they're the same, just return the input
		if (!fromUnit || !toUnit || fromUnit === toUnit) {
			return value;
		}

		// Look up conversion factors (to square metres)
		const fromFactor = this.lookup[fromUnit.toLowerCase()];
		const toFactor   = this.lookup[toUnit.toLowerCase()];

		if (!fromFactor || !toFactor) {
			throw new Error(`Unrecognized area unit: from=${fromUnit} to=${toUnit}`);
		}

		/**
		 * Convert $/fromUnit --> $/toUnit
		 * For cost conversions, we multiply by (toFactor / fromFactor).
		 * Example: $/m² -> $/ft² = value * (0.092903 / 1)
		 */
		return value * (toFactor / fromFactor);
	}
}
