import { BoqFilter } from './boq-filter';
import { ExportDocument } from './export-document';

export interface DocumentGeneratorParams {
	attachmentTypeName: string;
	clientIndex: number;
	projectId: string;
	templates: ExportDocument[];
	divisionFilter?: string;
	exportFilter?: BoqFilter;
	groupByField?: string;
	invoiceId?: string;
	purchaseOrderId?: string;
	skipPdfConversion?: boolean;
	variationId?: string;
	measureSystem?: string;
}
