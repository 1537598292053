import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { DecimalPipe } from '@angular/common';
import { MeasureSystemService } from '../services/measure-system.service';

@Pipe({
	name: 'length',
	pure: false,
	standalone: true,
})
export class LengthPipe implements PipeTransform {

	constructor(private measureSystemService: MeasureSystemService) {}

	/**
	 * Lookup table for converting any length unit to meters (m).
	 * Values represent how many meters are in one unit.
	 * For example:
	 *  - 1 foot (ft) = 0.3048 m
	 */
	private readonly lookup: { [unit: string]: number } = {
		// Metric
		mm: 0.001,
		cm: 0.01,
		m: 1,
		lm: 1,
		km: 1000,

		// Imperial/US
		in: 0.0254,
		ft: 0.3048,
		lft: 0.3048,
		yd: 0.9144,
		mi: 1609.344,
	};

	transform(value: number, from: string, toMetric: string, toImperial: string, system?: string): number {
		// Validate value
		if (typeof value !== 'number' || isNaN(value)) {
			throw new Error('value must be a valid number');
		}

		// If value is 0, the result is 0
		if (value === 0) {
			return 0;
		}

		// If no system was provided, use the user's preferred system
		if (!system) {
			system = this.measureSystemService.preferredSystem.value;
		}

		// Choose which target unit to use based on preference
		const to = system === 'imperial' ? toImperial : toMetric;

		// If any unit is missing or the same, just return the input value
		if (!from || !to || from === to) {
			return value;
		}

		const fromFactor = this.lookup[from.toLowerCase()];
		const toFactor = this.lookup[to.toLowerCase()];

		if (!fromFactor || !toFactor) {
			throw new Error(`Unrecognized length unit: from=${from}, to=${to}`);
		}

		// Convert: value(from) -> meters -> targetUnit
		return (value * fromFactor) / toFactor;
	}
}

@Pipe({
	name: 'lengthWithUnit',
	pure: false,
	standalone: true,
})
export class LengthUnitPipe implements PipeTransform {

	constructor(private measureSystemService: MeasureSystemService, private decimal: DecimalPipe) {}

	/**
	 * Lookup table mapping units to their display strings,
	 * e.g. 'm' -> 'm', 'ft' -> 'ft'.
	 * You can customize these abbreviations as needed.
	 */
	private readonly lookup: { [unit: string]: string } = {
		// Metric
		mm: 'mm',
		cm: 'cm',
		m: 'm',
		lm: 'lm',
		km: 'km',

		// Imperial/US
		in: 'in',
		ft: 'ft',
		lft: 'lft',
		yd: 'yd',
		mi: 'mi',
	};

	transform(value: number, from: string, toMetric: string, toImperial: string, format?: string): string {
		// Determine user preference
		const system = this.measureSystemService.preferredSystem.value;
		const to = system === 'imperial' ? toImperial : toMetric;

		// Perform the numeric conversion
		const raw = new LengthPipe(this.measureSystemService).transform(
			value, from, toMetric, toImperial, system
		);

		// Lookup the display abbreviation
		const unitLabel = this.lookup[to] || to;

		// Return the formatted string (e.g. "3.28 ft")
		return `${this.decimal.transform(raw, format)} ${unitLabel}`;
	}
}