import { Validators } from '@angular/forms';
import Decimal from 'decimal.js';

import { Constants } from '../helpers';
import { CustomValidators } from '../helpers';
import { ValidatorHelper } from '../helpers';
import { ReferenceCode } from './reference-code';

export interface Product {
	_id?: string;
	id?: string;
	title: string;
	productCode: string;
	productGroup: ReferenceCode;
	productType: ReferenceCode;
	finish: ReferenceCode;
	quality: ReferenceCode;
	base: string;
	brand: string;
	specSheetKey?: string;
	specSheetUrl?: string;
	msdsSheetKey?: string;
	msdsSheetUrl?: string;
	sizes: ProductSize[];
	applicationMethods: ProductApplicationMethod[];
	isActive: boolean;
	pictureKey?: string;
	pictureUrl?: string;
	imageUrl?: string;
	vocLevel: string;
}

export interface PartialProduct {
	id: string;
	title: string;
	isActive: boolean;
}

export interface ProductSize {
	size: number;
	cost: number;
	unit: string;
}

export interface HeightRangeRate {
	title: string;
	value: number;
}

export interface ProductApplicationMethod {
	type: string;
	spreadRate: number;
	heightRangeRates: HeightRangeRate[];
	applicationTargetRate: number;
	display?: boolean;
}

export interface ApplicationMethod {
	title: string;
	defaultApplicationTargetRate: number;
}

export interface HeightRange {
	title: string;
	heightRangeProportion: number;
	heightRangeValue: number;
}

export function GetSizeValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Size',
			validators: [Validators.required, Validators.min(0.01), Validators.max(9999)],
		},
		{
			name: 'Cost',
			validators: [Validators.required],
		},
	]);
}

export function GetProductValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'ProductCode',
			validators: [Validators.maxLength(300)],
		},
		{
			name: 'Title',
			validators: [Validators.required, Validators.maxLength(300), CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Base',
			validators: [Validators.maxLength(300)],
		},
		{
			name: 'Finish',
			validators: [Validators.required],
		},
		{
			name: 'Group',
			validators: [Validators.required],
		},
		{
			name: 'Quality',
			validators: [Validators.required],
		},
		{
			name: 'Type',
			validators: [Validators.required],
		},
		{
			name: 'vocLevel',
			validators: [Validators.required],
		},
		{
			name: 'measureSystem',
			validators: [Validators.required],
		}
	]);
}

/**
 * NOTES: DO NOT DO THIS!!! This is static for V1. Further validation will be dynamic
 * @returns {{'1': {}; '2': {}; '3': {}; '4': {}; '5': {}; '6': {}; '7': {}; '8': {}; '9': {}; '10': {}}}
 * @constructor
 */
export function GetHeightValidation(applicationMethods: Array<ApplicationMethod>) {
	const formBuilderArray = [];

	for (let i = 0; i < applicationMethods.length; i++) {
		const applicationMethod = applicationMethods[i];
		const formBuilder = ValidatorHelper.formBuilderWithDefault([
			{
				name: 'heightRange1',
				initValue: new Decimal(Constants.HEIGHT_RANGES['metric'][0].heightRangeProportion).times(applicationMethod.defaultApplicationTargetRate).round().toNumber(),
				validators: [Validators.min(0), Validators.required],
			},
			{
				name: 'heightRange2',
				initValue: new Decimal(Constants.HEIGHT_RANGES['metric'][1].heightRangeProportion).times(applicationMethod.defaultApplicationTargetRate).round().toNumber(),
				validators: [Validators.min(0), Validators.required],
			},
			{
				name: 'heightRange3',
				initValue: new Decimal(Constants.HEIGHT_RANGES['metric'][2].heightRangeProportion).times(applicationMethod.defaultApplicationTargetRate).round().toNumber(),
				validators: [Validators.min(0), Validators.required],
			},
			{
				name: 'heightRange4',
				initValue: new Decimal(Constants.HEIGHT_RANGES['metric'][3].heightRangeProportion).times(applicationMethod.defaultApplicationTargetRate).round().toNumber(),
				validators: [Validators.min(0), Validators.required],
			},
			{
				name: 'Rate',
				initValue: applicationMethod.defaultApplicationTargetRate,
				validators: [Validators.required, Validators.min(0)],
			},
			{
				name: 'SpreadRate',
				initValue: applicationMethod.defaultApplicationTargetRate,
				validators: [Validators.required, Validators.min(0)],
			},
		]);
		formBuilderArray[i] = formBuilder;
	}

	return formBuilderArray;
}
