import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Decimal } from 'decimal.js';

import { Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { projectStatuses } from '../../../components/project/common/project.constants';
import { Constants, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../helpers';
import {
	ApplicationMethod,
	BulkCharge,
	CoatingSystem,
	Division,
	EmbeddedBOQCoatProduct,
	GetBoqCoatingSystemValidation,
	GetBulkBoqCloneValidation,
	GetCoatingSystemProductValidation,
	GetCoatingSystemValidation,
	HeightRange,
	LabourRate,
	Product,
	Project,
	ProjectTime,
	ReferenceCode,
	TargetProject,
} from '../../../interfaces';
import { BrandModel, CoatingSystemModel, ColourModel, UserModel } from '../../../models';
import { CoatingSystemFormComponent } from '../../../pages/coating-system/coating-system-form/coating-system-form.component';
import { PaintInformationComponent } from '../../../pages/project/project-details/boq/paint-information/paint-information.component';
import { AuthService } from '../../../services/auth.service';
import { BrandService } from '../../../services/brand.service';
import { ChargeService } from '../../../services/charge.service';
import { DivisionService } from '../../../services/division.service';
import { ProductService } from '../../../services/product.service';
import { ProjectService } from '../../../services/project.service';
import { RefCodeService } from '../../../services/reference-code.service';
import { BulkBoqEditTextConst } from './common/bulk-boq-edit-dialog-constants';
import { RolePermissionsService } from '../../../services/role-permissions.service';

@Component({
	selector: 'app-bulk-boq-edit-dialog',
	templateUrl: './bulk-boq-edit-dialog.component.html',
	styleUrls: ['./bulk-boq-edit-dialog.component.scss'],
})
export class BulkBoqEditDialogComponent implements OnInit {
	@ViewChild(CoatingSystemFormComponent, { static: true })
	public coatingSystemFormRef: CoatingSystemFormComponent;
	@ViewChild(PaintInformationComponent, { static: true })
	public paintInformationFormRef: PaintInformationComponent;

	// @ViewChild(BoqCoatingSystemComponent, {static: false})

	@Input()
	public set selectedItems(boqIds: string[]) {
		if (boqIds) {
			this.setCommonCoatingSystem(boqIds);
		}
		this.resetBulkCharge();
	}

	@Input()
	public set currentUser(currentUser: UserModel) {
		if (currentUser) {
			this._currentUser = currentUser;
			this.getUserProjects(this.currentUser);
		}
	}
	public get currentUser(): UserModel {
		return this._currentUser;
	}
	@Input()
	public set referenceCodeTypes(referenceCodeTypes: ReferenceCode) {
		if (referenceCodeTypes) {
			this._referenceCodeTypes = referenceCodeTypes;
			this.profiles = this.referenceCodeTypes['profile'];
			this.substrates = this.referenceCodeTypes['substrate'];
			this.labourRates = this.referenceCodeTypes['labour_type'];
			if (this.profiles) {
				this.filterProfile();
			}
		}
	}
	public get referenceCodeTypes(): ReferenceCode {
		return this._referenceCodeTypes;
	}

	@Input()
	public discountEditable: boolean;
	@Input()
	public isAdditionalCharge: boolean = false;
	@Input()
	public isBoq = false;
	@Input()
	public isCloning: boolean;
	@Input()
	public project: Project;
	@Input()
	public variationId: string;

	public addTagText = 'New Sub Location:';
	public applicationMethods: Array<ApplicationMethod> = Constants.APPLICATION_METHODS_ARRAY;
	public brands: Array<BrandModel> = [];
	public brandsObservable: Observable<BrandModel[]>;
	public boqCoatingSystemValidators;
	public bulkBoqEditTextConst: typeof BulkBoqEditTextConst = BulkBoqEditTextConst;
	public bulkBoqEditValidators;
	public bulkCharge: BulkCharge;
	public coatingProductsValidators;
	public coatingSystem: CoatingSystem;
	public colour: ColourModel;
	public colours: ColourModel[];
	public divisions: Observable<Division[]>;
	public environments: Array<string> = Constants.ENVIRONMENTS;
	public filteredProfile: ReferenceCode[] = [];
	public getCommonCoatingSystem: Subscription;
	public isAdmin = false;
	public isM2 = false;
	public labourRates: ReferenceCode[];
	public measurements: Array<string> = Constants.MEASUREMENTS;
	public products: Product[];
	public productsObservable: Observable<Product[]>;
	public profiles: ReferenceCode[];
	public projectTime: ProjectTime;
	public rates: LabourRate[];
	public showBoq: boolean = undefined;
	public substrates: ReferenceCode[];
	public targetProject: TargetProject;
	public textConstants: typeof TEXT = TEXT;
	public userProjects: Project[] = [];
	public userProjectsObservable: Observable<Project[]>;

	private _currentUser: UserModel;
	private _referenceCodeTypes: ReferenceCode;

	constructor(
		private brandService: BrandService,
		private chargeService: ChargeService,
		private divisionService: DivisionService,
		private projectService: ProjectService,
		private productService: ProductService,
		private referenceCodeService: RefCodeService,
		private route: ActivatedRoute,
		private snack: SnackBarHelperComponent,
		private rolePermissionsService: RolePermissionsService
	) {}

	public ngOnInit(): void {
		this.showBoq = this.isBoq && this.isAdditionalCharge;
		this.resetBulkCharge();

		this.productsObservable = this.productService.postList({ isActive: true }).pipe(tap((products: Product[]) => (this.products = products)));
		this.brandsObservable = this.brandService.postList({ isActive: true }).pipe(tap((brands: BrandModel[]) => (this.brands = brands)));

		this.divisions = this.divisionService.postList({ isActive: true });
	}

	public clearInvalidInput(validators, formControlName: string): void {
		validators[formControlName].setValue('');
	}

	public unitTypeChanged(): void {
		this.bulkCharge.bulkBoq.dimensions.profile = undefined;
		this.filterProfile();
		this.checkM2();
		this.updateUnitsM2();
	}

	/**
	 * Update the Dimension Units (m2) value based on the profile and Unit value
	 */
	public updateUnitsM2() {
		if (!this.bulkCharge.bulkBoq.useEstimatedValues && this.bulkCharge.bulkBoq.dimensions.profile) {
			if (!this.bulkCharge.bulkBoq.dimensions.units) {
				this.bulkCharge.bulkBoq.dimensions.units = 0;
			}

			if (this.bulkCharge.bulkBoq.dimensions.profile && this.bulkCharge.bulkBoq.dimensions.profile.code1 && this.bulkCharge.bulkBoq.dimensions.units) {
				const float = parseFloat(this.bulkCharge.bulkBoq.dimensions.profile.code1);
				if (!isNaN(float)) {
					this.bulkCharge.bulkBoq.areaDimensionsUnits = new Decimal(this.bulkCharge.bulkBoq.dimensions.units).times(float).toNumber();
				}
			} else {
				this.bulkCharge.bulkBoq.areaDimensionsUnits = 0;
			}
		} else {
			this.bulkCharge.bulkBoq.areaDimensionsUnits = this.bulkCharge.bulkBoq.useEstimatedValues
				? this.bulkCharge.bulkBoq.estimatedDimensions.units
				: this.bulkCharge.bulkBoq.dimensions.units;
		}
	}

	/**
	 *
	 */
	public checkM2() {
		if (this.bulkCharge.bulkBoq.dimensions.unitType && this.bulkCharge.bulkBoq.dimensions.unitType === 'M2') {
			this.isM2 = true;
			this.bulkCharge.bulkBoq.dimensions.profile = undefined;
		} else {
			this.isM2 = false;
		}
	}

	/**
	 *
	 */
	public filterProfile() {
		if (this.bulkCharge.bulkBoq?.dimensions.unitType && this.bulkCharge.bulkBoq?.dimensions.unitType !== 'M2') {
			this.filteredProfile = [];
			this.profiles.forEach(profile => {
				if (profile.code2 === this.bulkCharge.bulkBoq.dimensions.unitType) {
					this.filteredProfile.push(profile);
				}
			});
		}
	}

	/**
	 * Called when the selected colour has been changed. Sets the boqs colour to the new colour
	 * @param colour
	 */
	public onColourChanged(colour: ColourModel) {
		this.colour = colour;
		this.bulkCharge.bulkBoq.colour = colour ? colour.id : undefined;
	}

	public onCoatingSystemProductChange() {
		this.boqCoatingSystemValidators.Substrate.validators = [Validators.required];
	}

	public onDifficultyChange(value: number): void {
		this.bulkCharge.bulkBoq.difficulty = Math.min(100, Math.max(0, value));
	}

	public onSave() {
		if (this.coatingSystem.title || this.coatingSystem.coatProducts?.length || this.coatingSystem.product || this.coatingSystem.substrate) {
			const errors = this.checkCoatingSystemForErrors();
			if (errors) {
				this.snack.snackError(errors);
				return;
			}

			// convert coating system to embedded coating system before sending to backend
			this.bulkCharge.bulkBoq.coatingSystem = {
				title: this.coatingSystem.title,
				coatProducts: this.coatingSystem.coatProducts,
				chargeOutRates: this.coatingSystem.chargeOutRates,
			};

			this.bulkCharge.bulkBoq.substrate = this.coatingSystem.substrate.id;
			this.bulkCharge.bulkBoq.product = this.coatingSystem.product.id;
		}

		if (this.isCloning) {
			this.bulkCharge.bulkBoq.targetProjectId = this.project.id;
			this.bulkCharge.bulkAdditionalCharge.targetProjectId = this.project.id;
		}
		// We must manually set these as the sublocation autocomplete component does not use the ngModel approach
		this.bulkCharge.bulkBoq.subLocation1 = this.bulkBoqEditValidators.SubLocation1.value;
		this.bulkCharge.bulkBoq.subLocation2 = this.bulkBoqEditValidators.SubLocation2.value;
		this.bulkCharge.bulkBoq.subLocation3 = this.bulkBoqEditValidators.SubLocation3.value;
		// Additional charge and boq sublocation value is shared when bulk editing/cloning per client request
		this.bulkCharge.bulkAdditionalCharge.subLocation1 = this.bulkBoqEditValidators.SubLocation1.value;
		const updateObj: any = JSON.parse(JSON.stringify(this.bulkCharge));
		// Remove null values when bulk editing
		this.removeFalseyValues(updateObj.bulkBoq);
		this.removeFalseyValues(updateObj.bulkAdditionalCharge);
		// Remove null values from dimensions and charge out rates objects
		this.removeFalseyValues(updateObj.bulkBoq.dimensions);
		this.removeFalseyValues(updateObj.bulkBoq.chargeOutAmounts);
		// If dimensions or charge out rate are not being updated, remove the key
		if (Object.keys(updateObj.bulkBoq.dimensions).length === 0) {
			delete updateObj.bulkBoq.dimensions;
		}
		if (this.bulkCharge.bulkBoq.dimensions && this.bulkCharge.bulkBoq.dimensions.profile && this.bulkCharge.bulkBoq.dimensions.profile.id) {
			updateObj.bulkBoq.dimensions.profile = this.bulkCharge.bulkBoq.dimensions.profile.id;
		}
		if (Object.keys(updateObj.bulkBoq.chargeOutAmounts).length === 0) {
			delete updateObj.bulkBoq.chargeOutAmounts;
		}

		if (this.bulkCharge.bulkBoq.difficulty <= 0) {
			updateObj.bulkBoq.difficulty = 0;
		}

		return updateObj;
	}

	public setUnitTypes(): void {
		this.checkM2();
		this.updateUnitsM2();
	}

	private removeFalseyValues(obj) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key) && !obj[key]) {
				delete obj[key];
			}
		}
	}

	public switchVisibleChargeType(isBoq: boolean): void {
		this.showBoq = isBoq;
	}

	private checkCoatingSystemForErrors(): string {
		this.boqCoatingSystemValidators = GetBoqCoatingSystemValidation();
		this.boqCoatingSystemValidators.Substrate.validators = [];
		this.coatingProductsValidators = [];

		let err: string = '';
		// add coatingSystem to bulkBoq only if Coating System has at least one coatProducts
		// then validate Coating System
		if (this.coatingSystem.coatProducts.length) {
			this.bulkCharge.bulkBoq.coatingSystem = this.coatingSystem;
			this.coatingSystem.coatProducts.map((value: EmbeddedBOQCoatProduct) => {
				const validators = new UntypedFormGroup(GetCoatingSystemProductValidation());
				validators.patchValue({
					ApplicationMethod: value.applicationMethod,
					Product: value.product,
					Percentage: value.percentage,
					SpreadRate: value.spreadRate,
					ApplicationRate: value.targetRate,
				});
				this.coatingProductsValidators.push(validators);
				return value;
			});
			this.coatingProductsValidators.map((e: UntypedFormGroup) => {
				err += ValidatorHelper.checkErrors(e.controls) || '';
				return e;
			});
		}
		const coatingSystemFormGroup = new UntypedFormGroup(this.boqCoatingSystemValidators);
		coatingSystemFormGroup.patchValue({
			Title: this.coatingSystem.title,
			Substrate: this.coatingSystem.substrate,
			Product: this.coatingSystem.product,
			CoatProducts: this.coatingSystem.coatProducts,
		});
		err += ValidatorHelper.checkErrors(coatingSystemFormGroup.controls) || '';

		return err;
	}

	private getUserProjects(currentUser: UserModel): void {
		this.userProjectsObservable = this.projectService.getUserProjects(currentUser.id).pipe(
			tap((projects: Project[]) => {
				// If not admin, only want to be able to clone to active/target projects
				if (this.rolePermissionsService.isAdmin) {
					this.userProjects = projects;
				} else {
					this.userProjects = projects.filter(project => {
						return project.status === projectStatuses.active.value || project.status === projectStatuses.target.value;
					});
				}
			})
		);
	}

	private resetBulkCharge(): void {
		this.bulkCharge = {
			bulkBoq: {
				coatingSystem: undefined,
				discount: undefined,
				division: undefined,
				environment: undefined,
				targetProjectId: undefined,
				useEstimatedValues: undefined,
				subLocation1: '',
				subLocation2: '',
				subLocation3: '',
				substrate: undefined,
				product: undefined,
				heightRange: undefined,
				difficulty: undefined,
				colour: undefined,
				dimensions: {
					unitType: undefined,
					profile: undefined,
					units: undefined,
				},
				estimatedDimensions: {
					unitType: undefined,
					profile: undefined,
					units: undefined,
				},
				chargeOutAmounts: {
					comment: undefined,
					internalComment: undefined,
					ratePerUnit: undefined,
				},
				areaDimensionsUnits: undefined,
			},
			bulkAdditionalCharge: {
				environment: undefined,
				description: undefined,
				discount: undefined,
				comment: undefined,
				subLocation1: '',
				targetProjectId: undefined,
			},
		};

		// Reset validators
		this.bulkBoqEditValidators = GetBulkBoqCloneValidation(this.bulkCharge);
	}

	/**
	 * Check if all selected boqs have the same Coating System,
	 * if so populate this.coatingSystem with its values, else create a new empty coatingSystem.
	 */
	private setCommonCoatingSystem(boqIds): void {
		// we need this subscription for ngBusy
		this.getCommonCoatingSystem = this.chargeService
			.getCommonCoatingSystem(boqIds, this.project.id)
			.pipe(
				finalize(() => {
					this.getCommonCoatingSystem.unsubscribe();
					this.boqCoatingSystemValidators = GetBoqCoatingSystemValidation();
					this.coatingProductsValidators = GetCoatingSystemValidation();
				})
			)
			.subscribe((commonCoatingSystem: CoatingSystem) => {
				let bulkCoatingSystem: CoatingSystem;
				if (commonCoatingSystem && commonCoatingSystem.coatProducts) {
					bulkCoatingSystem = commonCoatingSystem;
					if (bulkCoatingSystem.product && bulkCoatingSystem.substrate) {
						bulkCoatingSystem.brand = bulkCoatingSystem.product.brand;
					}
				} else {
					bulkCoatingSystem = new CoatingSystemModel({
						brand: '',
						coatProducts: [],
						ignoreList: [],
						product: undefined,
						substrate: undefined,
						title: '',
					});
				}
				// make deep copy of coating system
				this.coatingSystem = JSON.parse(JSON.stringify(bulkCoatingSystem));
			});
	}
}
