import { environment } from '../../environments/environment';
import { AddressDetail, ApplicationMethod, ContactDetail, HeightRange, UserRole } from '../interfaces';
import { FileFolderType } from '../pages/project/export/common/export.interfaces';
import { TEXT } from './text.constants';

const env = environment.env;

let BASE_API_URL;
let BASE_FRONTEND_URL;
let BASE_AVATAR_URL;

switch (env) {
	case 'local':
		BASE_API_URL = 'http://localhost:8880/api';
		BASE_FRONTEND_URL = 'http://localhost:4200';
		BASE_AVATAR_URL = 'http://localhost:8880/avatar';
		break;
	case 'development':
		BASE_API_URL = 'https://dev-api.paintprojex.com/api';
		BASE_FRONTEND_URL = 'https://dev.paintprojex.com';
		BASE_AVATAR_URL = 'https://dev-api.paintprojex.com/avatar';
		break;
	case 'production':
		BASE_API_URL = 'https://app-api.paintprojex.com/api';
		BASE_FRONTEND_URL = 'https://app.paintprojex.com/';
		BASE_AVATAR_URL = 'https://app-api.paintprojex.com/avatar';
		break;
	case 'ushergroup':
		BASE_API_URL = 'https://ushergroup-api.paintprojex.com/api';
		BASE_FRONTEND_URL = 'https://ushergroup.paintprojex.com/';
		BASE_AVATAR_URL = 'https://ushergroup-api.paintprojex.com/avatar';
		break;
	case 'na':
		BASE_API_URL = 'https://na-api.paintprojex.com/api';
		BASE_FRONTEND_URL = 'https://na.paintprojex.com/';
		BASE_AVATAR_URL = 'https://na-api.paintprojex.com/avatar';
	default:
		console.log('Invalid environment');
		break;
}

export const budgetFinancialYearsCurrentFuture = 3;
export const budgetFinancialYearsPast = 3;

/**
 *  Type enum for different theme colours
 */
export enum CHART_COLOURS {
	black = '#000000',
	grey = '#D0D0D0',
	white = '#ffffff',
	revenue = '#B2D500',
	profit = '#fbc300',
	labour = '#d9d9d9',
	material = '#b6c5e7',
	access = '#fde898',
	other = '#aeaaaa',
	green = '#15b500',
	yellow = '#ffa700',
	orange = '#ff751f',
	red = '#db0f34',
	red2 = '#FF603C',
	primary = '#76BC21',
	newGreen = '#16a34a',
	newLightGreen = '#a3d000',
	newOrange = '#f97316',
	newYellow = '#eab308',
	newRed = '#dc2626',
	transparent = 'rgba(255,255,255,0)',
}

export const projectStatuses = {
	active: 'active',
	declined: 'declined',
	lost: 'lost',
	quoteApproved: 'quoteApproved',
	quoteReady: 'quoteReady',
	submitted: 'submitted',
	target: 'target',
	won: 'won',
	live: 'live',
	completed: 'completed',
};

const PROFILE_DEFAULT_PICTURE = 'assets/img/profileimg.png';
const LOCAL_STORAGE_TOKEN_KEY = 'token';
const LOCAL_STORAGE_EXPIRES_AT_KEY = 'expiresAt';
const LOCAL_STORAGE_ROLE_KEY = 'role';
const LOCAL_STORAGE_PHONE_KEY = 'phone';
const LOCAL_STORAGE_FIRSTNAME_KEY = 'firstName';
const LOCAL_STORAGE_LASTNAME_KEY = 'lastName';
const LOCAL_STORAGE_EMAIL_KEY = 'email';
const LOCAL_STORAGE_USERID_KEY = 'userId';
const LOCAL_STORAGE_USER_PICTURE = 'userPicture';
const LOCAL_STORAGE_RECENT_PRODUCTS_KEY = 'recentProducts';

export const Placeholders = {
	select: 'Select...',
};

const EDIT_PROJECT_STATUS_LABELS = {
	client: 'Client',
	contractAdministrator: 'Contract Administrator',
	competitorLostTo: 'Competitor Lost To',
	currentStatus: 'Current Status',
	lostReason: 'Lost Reason',
	otherReason: 'Other Reason',
	projectManager: 'Project Manager',
	reason: 'Reason',
	wonReason: 'Won Reason',
};

export const MARKETING_TYPE = {
	coldCalling: { key: 'coldCalling', name: 'Cold Calling' },
	displayedAd: { key: 'displayedAd', name: 'Displayed Advertising' },
	socialMedia: { key: 'socialMedia', name: 'Social Media' },
	emailMarketing: { key: 'emailMarketing', name: 'Email Marketing' },
	directMail: { key: 'directMail', name: 'Direct Mail' },
	telemarketing: { key: 'telemarketing', name: 'Telemarketing' },
	onlineAd: { key: 'onlineAd', name: 'Advertising - Online' },
	printAd: { key: 'printAd', name: 'Advertising - Print' },
	media: { key: 'media', name: 'Media' },
	televisionAd: { key: 'televisionAd', name: 'Advertising - Television' },
	radioAd: { key: 'radioAd', name: 'Advertising - Radio' },
	tradeShow: { key: 'tradeShow', name: 'Trade Show' },
	sponsorship: { key: 'sponsorship', name: 'Sponsorship' },
	promotion: { key: 'promotion', name: 'Promotion' },
};
export const FIELDS_DAYS_OF_WEEK = [
	{
		day: 'Monday',
		open: '',
		close: '',
		isOpen: false,
	},
	{
		day: 'Tuesday',
		open: '',
		close: '',
		isOpen: false,
	},
	{
		day: 'Wednesday',
		open: '',
		close: '',
		isOpen: false,
	},
	{
		day: 'Thursday',
		open: '',
		close: '',
		isOpen: false,
	},
	{
		day: 'Friday',
		open: '',
		close: '',
		isOpen: false,
	},
	{
		day: 'Saturday',
		open: '',
		close: '',
		isOpen: true,
	},
	{
		day: 'Sunday',
		open: '',
		close: '',
		isOpen: false,
	},
];
export const PROJECT_STATUS = {
	active: { value: 'active', text: 'Active', ordinal: 3 },
	declined: { value: 'declined', text: 'Declined', ordinal: 2 },
	lost: { value: 'lost', text: 'Lost', ordinal: 8 },
	quoteApproved: { value: 'quoteApproved', text: 'Quote Approved', ordinal: 5 },
	quoteReady: { value: 'quoteReady', text: 'Quote Ready', ordinal: 4 },
	submitted: { value: 'submitted', text: 'Submitted', ordinal: 6 },
	target: { value: 'target', text: 'Target', ordinal: 1 },
	won: { value: 'won', text: 'Won', ordinal: 7 },
	live: { value: 'live', text: 'Live', ordinal: 9 },
	completed: { value: 'completed', text: 'Completed', ordinal: 10 },
};

export function getStatuses() {
	return Object.values(PROJECT_STATUS).sort((a, b) => {
		if (a.ordinal < b.ordinal) {
			return -1;
		} else if (a.ordinal > b.ordinal) {
			return 1;
		} else {
			return 0;
		}
	});
}

const PROJECT_STATUS_ARRAY = [
	PROJECT_STATUS.target,
	PROJECT_STATUS.declined,
	PROJECT_STATUS.active,
	PROJECT_STATUS.quoteReady,
	PROJECT_STATUS.quoteApproved,
	PROJECT_STATUS.submitted,
	PROJECT_STATUS.won,
	PROJECT_STATUS.lost,
	PROJECT_STATUS.live,
	PROJECT_STATUS.completed,
];

const VARIATION_PROJECT_STATUS_ARRAY = [PROJECT_STATUS.active, PROJECT_STATUS.won, PROJECT_STATUS.lost];

const PROJECT_STATUS_VALUE_ARRAY = [
	PROJECT_STATUS.target.value,
	PROJECT_STATUS.declined.value,
	PROJECT_STATUS.active.value,
	PROJECT_STATUS.quoteReady.value,
	PROJECT_STATUS.quoteApproved.value,
	PROJECT_STATUS.submitted.value,
	PROJECT_STATUS.won.value,
	PROJECT_STATUS.lost.value,
	PROJECT_STATUS.live.value,
	PROJECT_STATUS.completed.value,
];

export const LOCAL_STORAGE_KEYS = {
	chargesFilter: 'chargesFilter',
	currentSession: 'currentSession',
	displayPictureKey: 'displayPictureKey',
	entity: 'entity',
	expiresAt: 'expiresAt',
	previousUser: 'previousUser',
	projectSearch: 'projectSearch',
	projectSearchDisplayValues: 'projectSearchDisplayValues',
	recentProducts: 'recentProducts',
	rememberMe: 'rememberMe',
	token: 'token',
	userDetails: 'userDetails',
	selectedProject: 'selectedProject'
};

const PROJECT_CALCULATION_FIELDS = [
	'additionalChargesChargeOut',
	'additionalChargesCost',
	'averageTargetRate',
	'chargeOrder',
	'discount',
	'discountPercentage',
	'grossTotalChargeOut',
	'grossTotalCost',
	'gst',
	'gstChargeOut',
	'heightFeeChargeOut',
	'labourChargeOut',
	'labourCost',
	'labourHoursTotal',
	'materialMarkUp',
	'materialWasteChargeOut',
	'materialWasteCost',
	'netTotalChargeOut',
	'probability',
	'totalAccess',
	'totalIncTax',
	'totalLitre',
	'totalMarginPercent',
	'totalMaterial',
	'totalOther',
	'totalProfit',
	'totalUnit',
	'totalUnits',
];

const PROJECT_STATUS_REASONS = {
	declined: ['No Client Relationship', 'Project High Risk', 'Unrealistic Quoting Frame', 'Competitor Does All Their Work', 'Other'],
	won: ['Best Price', 'Price Matched/Bettered', 'Good Client Relationship', 'Offered best solution', 'Other'],
	lost: ['Too Expensive', 'Competitor Advantage', 'Poor Qualification', 'Contract Terms', 'Scope Clarity', 'Too Cheap', 'Other'],
};

const PROJECT_STATUS_STYLE_FILL = {
	target: 'fill-target',
	declined: 'fill-declined',
	active: 'fill-active',
	quoteReady: 'fill-quoteReady',
	quoteApproved: 'fill-quoteApproved',
	submitted: 'fill-submitted',
	won: 'fill-won',
	lost: 'fill-lost',
	live: 'fill-live',
	completed: 'fill-completed',
};

const PROJECT_STATUS_STYLE_BG = {
	all: 'primary',
	target: 'target',
	declined: 'declined',
	active: 'active',
	quoteReady: 'quoteReady',
	quoteApproved: 'quoteApproved',
	submitted: 'submitted',
	won: 'won',
	lost: 'lost',
	live: 'live',
	completed: 'completed',
};

// ***** These are messages for snackbar ******* //
const SELECT_DETAILS_MESSAGE = 'Please select a ';

const PASSWORD_MATCH_MESSAGE = 'The new passwords do not match.';
const BOQ_COMPONENT_FIELD_MESSAGE = 'Make sure Brand, Product and Substrate are selected!';
const FILE_TOO_BIG_MESSAGE = 'File is too big: Maximum file size is 1gb';
const PDF_ONLY_MESSAGE = 'Only PDF Files Allowed';
const VALIDATION_ERROR_UNIQUE_COMPANIES = 'Current company exists.';
const VALIDATION_ERROR_UNIQUE_LABOUR_COSTS = 'Current labour cost exists.';
const VALIDATION_ERROR_UNIQUE_CHARGE_OUT_RATE = 'A charge-out rate with that state already exists.';
const LOG_OUT_TO_SEE_CHANGES = 'Please log out and log back in to see changes.';
const ENTITY_USERS_LOG_OUT_TO_SEE_CHANGES = "Your entity's users must log out and log back in to see changes.";
const ONLY_ADMINS_MESSAGE = 'Only admins can do this.';
const DOCUMENT_GENERATED = 'Document generated successfully.';
const SAVE_CHARGES_FIRST = 'Please finish saving charges and charge order before saving the project';

const PLEASE_ALLOW_POPUP = 'Please Allow Popups';
const IE_DISABLED_MESSAGE = 'This feature is not supported on Internet Explorer.';

// Time tracking
const CURRENT_SESSION_BEGIN = 'currentSessionBegin';

// ******************************************** //
// ************* Modal Constants ************** //

enum MODAL_TEXT {
	confirm = 'Confirm',
	continue = 'Continue',
	complete = 'Complete',
	open = 'Open',
	gotIt = 'GOT IT!',
	requestToSave = 'Request to Save',
	submit = 'Submit',
	submitAndCreateNew = 'Submit and create new',
	save = 'Save',
	edit = 'Edit',
	delete = 'Delete',
	close = 'Close',
}

enum MODAL_STYLE {
	info = 'btn-info',
	dark = 'btn-outline-dark',
	outline = 'btn-outline-primary',
	primary = 'btn-primary',
	success = 'btn-success',
	warning = 'btn-warning',
	danger = 'btn-danger',
}

enum MODAL_TITLE {
	alert = 'Notifications',
	preview = 'Preview',
	contactPerson = 'Contact Person',
	addContactPerson = 'Add Contact Person',
	userDetails = 'User Details',
	attachment = 'Attachment',
	comment = 'Comment',
	addTemplate = 'Add Template',
	addAttachment = 'Add Attachment',
	addComment = 'Add Comment',
	task = 'Task',
	addNewCoatingSystem = 'Add New Coating System',
	mergeClient = 'Merge Client',
	location = 'Location',
	substrate = 'Substrate',
	email = 'Email',
	updatePicture = 'Update Picture',
	confirm = 'Confirm',
	confirmCompletion = 'Confirm Completion',
	addColour = 'Add Colour',
	colour = 'Colour',
	time = 'Time',
	changePassword = 'Change Password',
	edit = 'Edit Item',
	uploadFile = 'Upload File',
	editStatus = 'Edit Status',
	bulkUpdate = 'Bulk Update',
	importError = 'Import Error',
	importResult = 'Import Result',
	warning = 'Warning',
	coatingSystem = 'Coating System',
	editComments = 'Edit comments',
	editComment = 'Edit comment',
	editTemplate = 'Edit template',
	deleteTemplate = 'Delete template',
	createProjectVersion = 'Create Project Version',
	restoreProject = 'Restore Project',
}

enum MODAL_MESSAGE {
	applyDiscountOnProject = "Discount Option 'Apply discount on Project total' will reset discount amounts on Additional Charge and BOQ items",
	applyDiscountOnCharges = "Discount Option 'Apply discount evenly to BOQs & Additional Charges' will distribute Project discount amount amongst active Additional Charge and BOQ items",
	applyDiscountCustom = "Discount Option ' Apply discount from BOQs & Additional Charges' will reset Project discount amount and each Additional Charge and BOQ items discount amount is adjustable individually. Confirming submit will take you to the charges table",
	confirmCompletionAndNewTask = 'Do you want to submit task completion and create a new task?',
	warningModifyingState = 'Modifying Project State will not affect Bill of Quantity/Additional Charge current Rates.',
	userRankNotAllowedEmail = "You are not authorized to email the client. You must change the project's status to 'Quote Ready' and wait for approval.",
}

// ******************************************** //

/**
 * File types for upload component
 */
export enum FileMimeTypeEnum {
	csv = 'text/csv',
	application_csv = 'application/csv',
	docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	jpg = 'image/jpg',
	jpeg = 'image/jpeg',
	png = 'image/png',
	pdf = 'application/pdf',
	svg = 'image/svg+xml',
	doc = 'application/msword',
	txt = 'text/plain',
	ppt = 'application/vnd.ms-powerpoint',
	pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	xls = 'application/vnd.ms-excel',
	xlsm = 'application/vnd.ms-excel.sheet.macroenabled.12',
	xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	xml = 'application/xml',
	xml_readable = 'text/xml',
	ods = 'application/vnd.oasis.opendocument.spreadsheet',
	odp = 'application/vnd.oasis.opendocument.presentation',
	odt = 'application/vnd.oasis.opendocument.text',
	pub = 'application/x-mspublisher',
	rar = 'application/vnd.rar',
	zip = 'application/zip',
	x_zip_compressed = 'application/x-zip-compressed',
}

export enum Units {
	hrs = 'hrs',
	lts = 'lts',
	m2 = 'm\u00B2',
	m2hr = 'm\u00B2/hr',
}

export const uploadAllowedTypes = {
	files: {
		type: 'file',
		array: [
			FileMimeTypeEnum.application_csv,
			FileMimeTypeEnum.jpg,
			FileMimeTypeEnum.jpeg,
			FileMimeTypeEnum.png,
			FileMimeTypeEnum.svg,
			FileMimeTypeEnum.txt,
			FileMimeTypeEnum.pdf,
			FileMimeTypeEnum.docx,
			FileMimeTypeEnum.doc,
			FileMimeTypeEnum.ppt,
			FileMimeTypeEnum.pptx,
			FileMimeTypeEnum.xls,
			FileMimeTypeEnum.xlsm,
			FileMimeTypeEnum.xlsx,
			FileMimeTypeEnum.xml,
			FileMimeTypeEnum.xml_readable,
			FileMimeTypeEnum.ods,
			FileMimeTypeEnum.odp,
			FileMimeTypeEnum.odt,
			FileMimeTypeEnum.pub,
			FileMimeTypeEnum.csv,
			FileMimeTypeEnum.zip,
			FileMimeTypeEnum.rar,
			FileMimeTypeEnum.x_zip_compressed,
		],
	},
	allDocuments: {
		type: 'document',
		array: [FileMimeTypeEnum.docx, FileMimeTypeEnum.pdf],
	},
	images: {
		type: 'image',
		array: [FileMimeTypeEnum.jpg, FileMimeTypeEnum.jpeg, FileMimeTypeEnum.png],
	},
	pdf: {
		type: 'pdf',
		array: [FileMimeTypeEnum.pdf],
	},
	csv: {
		type: 'csv',
		array: [FileMimeTypeEnum.csv, FileMimeTypeEnum.application_csv],
	},
	viewable: {
		type: 'viewable',
		array: [
			FileMimeTypeEnum.docx,
			FileMimeTypeEnum.doc,
			FileMimeTypeEnum.pdf,
			FileMimeTypeEnum.xls,
			FileMimeTypeEnum.xlsm,
			FileMimeTypeEnum.xlsx,
			FileMimeTypeEnum.txt,
			FileMimeTypeEnum.ppt,
			FileMimeTypeEnum.pptx,
			FileMimeTypeEnum.xml,
			FileMimeTypeEnum.xml_readable,
		],
	},
};

const FORM_STATES = {
	ADD: 'Add',
	EDIT: 'Edit',
	VIEW: 'View',
};

const LEAD_REFERENCES = {
	prospecting: 'Prospecting',
	existingClient: 'Existing Client',
	externalReferral: 'External Referral',
	advertising: 'Advertising',
	website: 'Website',
	marketing: 'Marketing',
	bidcontender: 'Bidcontender',
	procore: 'Procore',
	estimateOne: 'Estimate one',
	aconex: 'Aconex',
};

const LEAD_REFERENCES_US = {
	existingClient: 'Existing Client',
	referrals: 'Referrals',
	listBrokers: 'List Brokers',
	digitalmarketingLeadSources: 'Digital Marketing Lead Sources',
	socialMediaMarketing: 'Social Media Marketing',
	organicSearchSEO: 'Organic Search / SEO',
	contentMarketing: 'Content Marketing',
	emailMarketing: 'Email Marketing',
	paidAds: 'Paid Ads',
	traditionalMarketingLeadSources: 'Traditional Marketing',
	directCalls: 'Direct Calls',
	phoneCalls: 'Phone Calls',
	prLeadSources: 'PR Lead Sources',
	pressReleases: 'Press Releases',
	mediaCoverages: 'Media Coverages',
	sponsorships: 'Sponsorships',
	events: 'Events',
	directMarketing: 'Direct Marketing',
	competitors: 'Competitors',
	tendersinfo: 'Tendersinfo',
	tendersOnTime: 'Tenders on time',
	globalTenders: 'Global Tenders',
	bidDetail: 'Bid Detail',
	tenderImpulse: 'Tender Impulse',
	bidNet: 'Bid Net',
	bidUSA: 'Bid USA',
}

const SUPPLIER_TYPES = {
	product: { key: 'product', name: 'Product' },
	equipment: { key: 'equipment', name: 'Equipment' },
};

const TERM_DAYS = ['7', '14', '30', '45', 'PIA'];

const DEFAULT_PAYMENT_TERM_DAYS = '14';

const PERIOD_TYPES: Array<string> = ['Minutes', 'Hours', 'Days', 'Weeks'];

const TASK_TYPES = {
	user: 'User',
	target: 'Target',
	submitted: 'Submitted',
	quoteApproved: 'QuoteApproved',
	quoteDueDate: 'QuoteDueDate',
	followUp: 'FollowUp',
};

const TASK_ACTIONS = {
	quoteDueDate: 'Quote due date',
};

const CHARGE_TYPES = {
	Boq: 'Boq',
	AdditionalCharge: 'AdditionalCharge',
};

const ALERT_STATUS = {
	read: 'read',
	unread: 'unread',
	archive: 'archive',
	unarchive: 'unarchive',
};

const PAGINATE_ITEMS_5PER_PAGE = 5;
const PAGINATE_ITEMS_10PER_PAGE = 10;
const PAGINATE_ITEMS_25PER_PAGE = 25;
const PAGINATE_ITEMS_50PER_PAGE = 50;

export const matAutocompleteDropdownLimit: number = 50;

const NO_FILE_CHOSEN = 'No file chosen';

const APPLICATION_METHODS = {
	'Brush N Roll': { title: 'Brush N Roll', defaultApplicationTargetRate: 24 },
	'Cross Hatch': { title: 'Cross Hatch', defaultApplicationTargetRate: 8 },
	'Crows Feet': { title: 'Crows Feet', defaultApplicationTargetRate: 8 },
	'Hawk and Trowel': {
		title: 'Hawk and Trowel',
		defaultApplicationTargetRate: 5,
	},
	Install: { title: 'Install', defaultApplicationTargetRate: 5 },
	'Lambs Mitt': { title: 'Lambs Mitt', defaultApplicationTargetRate: 12 },
	'Mesh Application': {
		title: 'Mesh Application',
		defaultApplicationTargetRate: 5,
	},
	'Rag Roll': { title: 'Rag Roll', defaultApplicationTargetRate: 8 },
	'Render Pump': { title: 'Render Pump', defaultApplicationTargetRate: 7 },
	Sponge: { title: 'Sponge', defaultApplicationTargetRate: 8 },
	'Spray - Airless': {
		title: 'Spray - Airless',
		defaultApplicationTargetRate: 40,
	},
	'Spray - Conventional': {
		title: 'Spray - Conventional',
		defaultApplicationTargetRate: 30,
	},
	'Spray - HVLP': { title: 'Spray - HVLP', defaultApplicationTargetRate: 20 },
	'Spray - Weed Sprayer': {
		title: 'Spray - Weed Sprayer',
		defaultApplicationTargetRate: 40,
	},
	'Texture Plastic Trowel': {
		title: 'Texture Plastic Trowel',
		defaultApplicationTargetRate: 12,
	},
	'Texture Roller - Fine Sleeve': {
		title: 'Texture Roller - Fine Sleeve',
		defaultApplicationTargetRate: 12,
	},
	'Texture Roller - Medium Sleeve': {
		title: 'Texture Roller - Medium Sleeve',
		defaultApplicationTargetRate: 10,
	},
	'Texture Roller - Thick Sleeve': {
		title: 'Texture Roller - Thick Sleeve',
		defaultApplicationTargetRate: 8,
	},
	Trowel: { title: 'Trowel', defaultApplicationTargetRate: 15 },
	'Verticle Tipp Off': {
		title: 'Verticle Tipp Off',
		defaultApplicationTargetRate: 12,
	},
	Wallpaper: { title: 'Wallpaper', defaultApplicationTargetRate: 5 },
};

export const HEIGHT_RANGES = {
	metric: [
		{ title: '0-3m', heightRangeProportion: 1.0, heightRangeValue: 100 },
		{ title: '0-6m', heightRangeProportion: 0.75, heightRangeValue: 150 },
		{ title: '3-6m', heightRangeProportion: 0.75, heightRangeValue: 150 },
		{ title: '6+m',  heightRangeProportion: 0.6, heightRangeValue: 200 },
	],
	imperial: [
		{ title: '0-10ft', heightRangeProportion: 1.0, heightRangeValue: 100 },
		{ title: '0-20ft', heightRangeProportion: 0.75, heightRangeValue: 150 },
		{ title: '10-20ft', heightRangeProportion: 0.75, heightRangeValue: 150 },
		{ title: '20+ft', heightRangeProportion: 0.6, heightRangeValue: 200 },
	]
};

const ENVIRONMENTS: Array<string> = ['Exterior', 'Interior', 'Maintenance', 'Non-Specific', 'Repaint'];

export enum measurementTypes {
	m2 = 'M2',
	linealMetre = 'Lineal Metre',
	item = 'Item',
}

export enum measurementAbbreviation {
	M2 = 'M2',
	'Lineal Metre' = 'LM',
	Item = 'Item',
}

const MEASUREMENTS: Array<string> = [measurementTypes.m2, measurementTypes.linealMetre, measurementTypes.item];

const TARGET_RATES_TITLES = {
	targetRate: 'Target Rate',
	targetRateByDifficulty: 'Target Rate by Difficulty',
};

const TARGET_RATES_TYPES = {
	M2: 'm\u00B2/hr',
	'Lineal Metre': 'lm/hr',
	Item: 'm\u00B2/hr',
};

const USER_ROLES = {
	admin: { name: 'Admin', title: 'admin', rank: 3 },
	user: { name: 'User', title: 'user', rank: 2 },
	trainee: { name: 'Trainee', title: 'trainee', rank: 1 },
};

// This constants are in logical order from Highest Rank "1" to Lowest Rank
export const UserRoles = {
	superAdmin: { key: 'superAdmin', rank: 1, title: 'super admin' },
	admin: { key: 'admin', rank: 2, title: 'admin' },
	teamLeader: { key: 'teamLeader', rank: 3, title: 'team leader' },
	contracts: { key: 'contracts', rank: 4, title: 'contracts' },
	user: { key: 'user', rank: 5, title: 'user' },
	operations: { key: 'operations', rank: 6, title: 'operations' },
	field: { key: 'field', rank: 7, title: 'field' },
	trainee: { key: 'trainee', rank: 8, title: 'trainee' },
	viewer: { key: 'viewer', rank: 9, title: 'viewer' },
	employee: { key: 'employee', rank: 10, title: 'employee' },
};

const LIBRARY_HEADER_TYPES = {
	base: {
		key: 'base',
		name: 'Base',
		cardName: 'Base',
		isBase: true,
	},
	colourStatus: {
		key: 'isActive',
		name: 'Status',
		cardName: 'Status',
		isStatus: true,
	},
	description: {
		key: 'description',
		name: 'Description',
		cardName: 'Description',
	},
	isActiveStatus: {
		key: 'isActive',
		name: 'Status',
		cardName: 'Status',
		isStatus: true,
	},
	supplierName: {
		name: 'Supplier Name',
		cardName: 'Supplier Name',
		key: 'supplierName',
	},
	productName: {
		name: 'Product Name',
		key: 'productName',
		cardNey: 'productName',
	},
	id: {
		key: 'id',
		name: 'ID',
		cardName: 'ID',
	},
	projectRef: {
		key: 'status',
		name: 'ID#',
		cardName: 'ID#',
		class: 'col-1',
		isProjectStatus: true,
	},
	name: {
		key: 'name',
		name: 'Name',
		cardName: 'Name',
	},
	colourName: {
		key: 'name',
		name: 'Colour Name',
		cardName: 'Colour Name',
	},
	clientName: {
		key: 'name',
		name: 'Name',
		cardName: 'Name',
		class: 'col-2',
	},
	companyName: {
		key: 'name',
		name: 'Company Name',
		cardName: 'Name',
		class: 'col-3',
	},
	details: {
		key: 'details',
		name: 'Details',
		cardName: 'Details',
		class: 'col-3',
	},
	title: {
		key: 'title',
		name: 'Title',
		cardName: 'Title',
	},
	productTitle: {
		key: 'title',
		class: 'col-4',
		name: 'Title',
		cardName: 'Title',
	},
	firstName: {
		key: 'firstName',
		name: 'First Name',
	},
	lastName: {
		key: 'lastName',
		name: 'Last Name',
		cardName: 'Last Name',
	},
	email: {
		key: 'email',
		name: 'Email',
		cardName: 'Email',
		class: 'col-4',
	},
	role: {
		key: 'role',
		name: 'Role',
		cardName: 'Role',
	},
	clientEmail: {
		key: 'email',
		name: 'Email',
		cardName: 'Email',
		class: 'col-2',
	},
	companyEmail: {
		key: 'email',
		name: 'Email',
		cardName: 'Email',
		class: 'col-3',
	},
	hexCode: {
		key: 'hexColour',
		name: 'Hex Code',
		cardName: 'Hex Code',
		isHex: true,
	},
	hexColour: {
		key: 'hexColour',
		name: 'Colour',
		cardName: 'Colour',
		isColour: true,
	},
	code: {
		key: 'code',
		name: 'Colour Code',
		cardName: 'Code',
	},
	productCode: {
		key: 'productCode',
		name: 'Code',
		cardName: 'Code',
	},
	abbreviation: {
		key: 'abbreviation',
		name: 'Abbreviation',
		cardName: 'Abbreviation',
	},
	logoUrl: {
		key: 'logoUrl',
		name: 'Logo',
		cardName: 'Logo',
		isImage: true,
	},
	state: {
		key: 'state',
		name: 'State',
		cardName: 'State',
		class: 'col-2',
		path: ['state', 'abbreviation'],
	},
	stateClient: {
		key: 'state',
		name: 'State',
		cardName: 'State',
		class: 'col-2',
		path: ['state', 'abbreviation'],
	},
	labourType: {
		key: 'labourType',
		name: 'Labour Type',
		cardName: 'Labour Type',
		path: ['labourType', 'title'],
	},
	qualification: {
		key: 'qualification',
		name: 'Qualification',
		cardName: 'Qualification',
		path: ['qualification', 'title'],
	},
	rateEBA: {
		key: 'rateEBA',
		name: 'EBA $ Rate',
		cardName: 'EBA $ Rate',
	},
	rateNonEBA: {
		key: 'rateNonEBA',
		name: 'Non-EBA $ Rate',
		cardName: 'Non-EBA $ Rate',
	},
	afterHoursRateEba: {
		key: 'afterHoursRateEba',
		name: 'After Hours EBA $ Rate',
	},
	afterHoursRateNonEba: {
		key: 'afterHoursRateNonEba',
		name: 'After Hours Non-EBA $ Rate',
	},
	contactWebsite: {
		key: 'contactWebsite',
		name: 'Website',
		cardName: 'Website',
		path: ['contactDetail', 'website'],
	},
	contactEmail: {
		key: 'contactEmail',
		name: 'Email',
		cardName: 'Email',
		path: ['contactDetail', 'email'],
	},
	contactPhone: {
		key: 'contactPhone',
		name: 'Phone',
		cardName: 'Phone',
		path: ['contactDetail', 'phone'],
	},
	mainContactName: {
		key: 'mainContactName',
		name: 'Contact Name',
		cardName: 'Contact Name',
		pathFirstName: ['mainContact', 'firstName'],
		pathLastName: ['mainContact', 'lastName'],
	},
	numProducts: {
		key: 'numProducts',
		name: 'No. of Products',
		cardName: 'No. of Products',
		path: ['products', 'length'],
	},
	numRegions: {
		key: 'numRegions',
		name: 'No. of Regions',
		cardName: 'No. of Regions',
		path: ['regions', 'length'],
	},
	isInclusion: {
		key: 'isInclusion',
		name: 'Type',
		cardName: 'Type',
	},
	company: {
		key: 'company',
		name: 'Company',
		cardName: 'Company',
	},
	templateTitle: {
		key: 'templateTitle',
		name: 'Title',
		cardName: 'Title',
	},
	teamLeader: {
		key: 'teamLeader',
		name: 'Team Leader',
		cardName: 'Team Leader',
		pathFirstName: ['teamLeader', 'firstName'],
		pathLastName: ['teamLeader', 'lastName'],
	},
	numUsers: {
		key: 'numUsers',
		name: 'No. of Users',
		cardName: 'No. of Users',
		path: ['users', 'length'],
	},
	pictureKey: {
		key: 'pictureKey',
		name: 'Product Image',
		cardName: 'Product Image',
		class: 'col-2',
		isImage: true,
	},
	finish: {
		key: 'finish',
		name: 'Finish',
		cardName: 'Finish',
		class: 'col-2',
		path: ['finish', 'title'],
	},
	quality: {
		key: 'quality',
		name: 'Quality',
		cardName: 'Quality',
		class: 'col-2',
		path: ['quality', 'title'],
	},
	phone: {
		key: 'phone',
		name: 'Phone',
		cardName: 'Phone',
		class: 'col-2',
	},
	fax: {
		key: 'fax',
		name: 'Fax',
		cardName: 'Fax',
	},
	addressLine1: {
		key: 'addressLine1',
		name: 'Address Line 1',
		cardName: 'Address Line 1',
	},
	addressLine2: {
		key: 'addressLine2',
		name: 'Address Line 2',
		cardName: 'Address Line 2',
	},
	addressLine3: {
		key: 'addressLine3',
		name: 'Address Line 3',
		cardName: 'Address Line 3',
	},
	city: {
		key: 'city',
		name: 'City',
		cardName: 'City',
		class: 'col-2',
	},
	contact: {
		key: 'displayedContact',
		name: 'Contact',
		cardName: 'Contact',
		path: ['displayedContact', 'name'],
	},
	postCode: {
		key: 'postalCode',
		name: 'Postal Code',
		cardName: 'Postal Code',
	},
	imageUrl: {
		key: 'imageUrl',
		name: 'Logo',
		cardName: 'Logo',
		class: 'col-2',
		isImage: true,
		disableLabelOnCard: true,
	},
	status: {
		key: 'status',
		name: 'Status',
		cardName: 'Status',
		class: 'col-1',
		isStatusPill: true,
	},
	projectName: {
		key: 'name',
		name: 'Project Name',
		cardName: 'Project Name',
		class: 'col-1',
	},
	variations: {
		key: 'variations',
		name: 'Var.',
		cardName: 'Var.',
		class: 'variations-chevron-col',
		isVariation: true,
	},
	createdAt: {
		key: 'createdAt',
		name: 'Created',
		cardName: 'Created',
		class: 'col-1',
		isDate: true,
	},
	clients: {
		key: 'clientsName',
		name: 'Clients',
		cardName: 'Clients',
		isClient: true,
		class: 'clients-col',
	},
	clientKeyContacts: {
		key: 'clientsContact',
		name: 'Key Contacts',
		cardName: 'Key Contacts',
		isClientContact: true,
		class: 'col-1',
	},
	comments: {
		key: 'comments',
		name: 'Comments',
		cardName: 'Comments',
		class: 'col-1',
		isComment: true,
	},
	estimator: {
		key: 'estimator',
		name: 'Estimator',
		cardName: 'Estimator',
		class: 'col-1',
		pathFirstName: ['estimator', 'firstName'],
		pathLastName: ['estimator', 'lastName'],
	},
	leadReference: {
		key: 'leadReference',
		name: 'Lead Source',
		cardName: 'Lead Source',
	},
	netTotalChargeOut: {
		key: 'netTotalChargeOut',
		name: 'Project Value',
		cardName: 'Project Value',
		class: 'col-1',
		isCurrency: true,
	},
	rgb: {
		key: 'hexColour',
		name: 'Red/Green/Blue',
		cardName: 'Red/Green/Blue',
		isRGB: true,
	},
	totalProfit: {
		key: 'totalProfit',
		name: 'Total Profit',
		cardName: 'Total Profit',
		class: 'col-1',
		isCurrency: true,
	},
	totalMarginPercent: {
		key: 'totalMarginPercent',
		name: 'GP %',
		cardName: 'GP %',
		class: 'col-1',
		isPercent: true,
	},
	totalIncTax: {
		key: 'totalIncTax',
		name: 'Project Value',
		cardName: 'Project Value',
		class: 'col-1',
		isCurrency: true,
	},
	brand: {
		key: 'brand',
		name: 'Colour Brand',
		cardName: 'Colour Brand',
	},
	userToolType: {
		key: 'userToolType',
		name: 'Category',
		cardName: 'Category',
		isUserTool: true,
		class: 'text-capitalize',
	},
	canvasStyleDetails: {
		key: 'canvasStyleDetails',
		name: 'Canvas Style Colours',
		cardName: 'Canvas Style Colours',
		isColourRect: true,
		path: ['canvasStyleDetails'],
	},
	winProbability: {
		key: 'probability',
		name: 'Win Probability %',
		cardName: 'Win Probability %',
		class: 'col-1',
		isPercent: true,
	},
};

const LIBRARY_TYPES = {
	brand: {
		key: 'brand',
		name: 'Brands',
		itemName: 'Brand',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.logoUrl],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.logoUrl],
		fields: '_id name isActive logo order documentType',
		showEditOrder: true,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	coatingSystem: {
		key: 'coatingSystem',
		name: 'Coating Systems',
		itemName: 'Coating System',
		cardHeaders: [LIBRARY_HEADER_TYPES.title],
		headers: [LIBRARY_HEADER_TYPES.title],
		fields: '_id title isActive coatProducts order documentType',
		showBrands: true,
		showCompaniesAndParent: false,
		showEditOrder: true,
		isBulkEdit: true,
		showGridToggle: true,
	},
	colour: {
		key: 'colour',
		name: 'Colour',
		itemName: 'Colour',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.code],
		headers: [
			LIBRARY_HEADER_TYPES.hexColour,
			LIBRARY_HEADER_TYPES.name,
			LIBRARY_HEADER_TYPES.rgb,
			LIBRARY_HEADER_TYPES.hexCode,
			LIBRARY_HEADER_TYPES.code,
			LIBRARY_HEADER_TYPES.base,
			LIBRARY_HEADER_TYPES.colourStatus,
		],
		fields: '_id name base blue green isActive hexColour code red status order documentType',
		showBrands: true,
		showSearch: true,
		showColourName: true,
		showCompaniesAndParent: false,
		showEditOrder: true,
		isBulkEdit: true,
		showGridToggle: true,
	},
	division: {
		key: 'division',
		name: 'Divisions',
		itemName: 'Division',
		headers: [LIBRARY_HEADER_TYPES.company, LIBRARY_HEADER_TYPES.details, LIBRARY_HEADER_TYPES.teamLeader],
		cardHeaders: [LIBRARY_HEADER_TYPES.company, LIBRARY_HEADER_TYPES.details, LIBRARY_HEADER_TYPES.teamLeader],
		fields: '_id description name isActive order',
		showBrands: false,
		showSearch: false,
		showColourName: false,
		showCompaniesAndParent: false,
		showEditOrder: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	projectColour: {
		key: 'colour',
		name: 'Colours',
		itemName: 'Colour',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.hexColour, LIBRARY_HEADER_TYPES.code, LIBRARY_HEADER_TYPES.brand],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.hexColour, LIBRARY_HEADER_TYPES.code, LIBRARY_HEADER_TYPES.brand],
		fields: '_id name isActive hexColour code brand order documentType',
		showBrands: true,
		showCompaniesAndParent: false,
		showEditOrder: true,
		isBulkEdit: true,
		showGridToggle: true,
	},
	product: {
		key: 'product',
		name: 'Products',
		itemName: 'Product',
		cardHeaders: [
			LIBRARY_HEADER_TYPES.productTitle,
			LIBRARY_HEADER_TYPES.productCode,
			LIBRARY_HEADER_TYPES.finish,
			LIBRARY_HEADER_TYPES.quality,
			LIBRARY_HEADER_TYPES.pictureKey,
		],
		headers: [LIBRARY_HEADER_TYPES.productTitle, LIBRARY_HEADER_TYPES.productCode, LIBRARY_HEADER_TYPES.finish, LIBRARY_HEADER_TYPES.quality, LIBRARY_HEADER_TYPES.pictureKey],
		fields: '_id title isActive productCode productGroup productType finish quality pictureKey pictureUrl order documentType',
		showBrands: true,
		showCompaniesAndParent: false,
		showEditOrder: true,
		isBulkEdit: false,
		showGridToggle: true,
	},
	rate: {
		key: 'rate',
		name: 'Labour Rates',
		itemName: 'Labour Rate',
		cardHeaders: [LIBRARY_HEADER_TYPES.state, LIBRARY_HEADER_TYPES.labourType, LIBRARY_HEADER_TYPES.rateEBA, LIBRARY_HEADER_TYPES.rateNonEBA],
		headers: [
			LIBRARY_HEADER_TYPES.state,
			LIBRARY_HEADER_TYPES.labourType,
			LIBRARY_HEADER_TYPES.rateEBA,
			LIBRARY_HEADER_TYPES.rateNonEBA,
			LIBRARY_HEADER_TYPES.afterHoursRateEba,
			LIBRARY_HEADER_TYPES.afterHoursRateNonEba,
		],
		fields: '_id state isActive labourType rateEBA rateNonEBA afterHoursRateEba afterHoursRateNonEba order',
		showEditOrder: true,
		showCompaniesAndParent: false,
		isBulkEdit: true,
		showGridToggle: true,
	},
	state: {
		key: 'state',
		name: 'States & Regions',
		itemName: 'State',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.abbreviation, LIBRARY_HEADER_TYPES.numRegions],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.abbreviation, LIBRARY_HEADER_TYPES.numRegions],
		fields: '_id name isActive abbreviation regions',
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	supplier: {
		key: 'supplier',
		name: 'Suppliers',
		itemName: 'Supplier',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.numProducts],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.numProducts],
		fields: '_id name isActive contactDetail mainContact products order documentType',
		showEditOrder: true,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	user: {
		key: 'user',
		name: 'Users',
		itemName: 'User',
		cardHeaders: [LIBRARY_HEADER_TYPES.firstName, LIBRARY_HEADER_TYPES.lastName, LIBRARY_HEADER_TYPES.email, LIBRARY_HEADER_TYPES.role],
		headers: [LIBRARY_HEADER_TYPES.firstName, LIBRARY_HEADER_TYPES.lastName, LIBRARY_HEADER_TYPES.email, LIBRARY_HEADER_TYPES.role],
		fields: '_id isActive firstName lastName email role',
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	measureTool: {
		key: 'measureTool',
		name: 'Measure Tools',
		itemName: 'Measure Tool',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.canvasStyleDetails, LIBRARY_HEADER_TYPES.userToolType],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.canvasStyleDetails, LIBRARY_HEADER_TYPES.userToolType],
		fields: '_id name canvasStyleDetails userToolType isUserTool',
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	userTool: {
		key: 'userTool',
		name: 'User Tools',
		itemName: 'User Tool',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.canvasStyleDetails, LIBRARY_HEADER_TYPES.userToolType],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.canvasStyleDetails, LIBRARY_HEADER_TYPES.userToolType],
		fields: '_id name canvasStyleDetails userToolType isUserTool',
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	refCode: {
		key: 'refCode',
		name: 'Reference Codes',
		itemName: 'Reference Code',
		cardHeaders: [LIBRARY_HEADER_TYPES.title],
		headers: [LIBRARY_HEADER_TYPES.title],
		fields: '_id isActive title order',
		showEditOrder: true,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	incExcCommentTemplate: {
		key: 'incExcCommentTemplate',
		name: 'Inclusion/Exclusion Templates',
		itemName: 'Inclusion/Exclusion Template',
		cardHeaders: [LIBRARY_HEADER_TYPES.title, LIBRARY_HEADER_TYPES.isInclusion],
		headers: [LIBRARY_HEADER_TYPES.title, LIBRARY_HEADER_TYPES.isInclusion],
		fields: '_id isActive title isInclusion',
		showCompanies: true,
		showEditOrder: true,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	filesFolders: {
		key: 'filesFolders',
		name: 'Files/Folders',
		itemName: 'File/Folder',
		cardHeaders: [LIBRARY_HEADER_TYPES.templateTitle],
		headers: [LIBRARY_HEADER_TYPES.templateTitle],
		fields: '_id isActive folder templateTitle fileKey order documentType',
		showInactive: true,
		showCompanies: false,
		showEditOrder: true,
		showCompaniesAndParent: true,
		isBulkEdit: false,
		showGridToggle: true,
	},
	competitor: {
		key: 'competitor',
		name: 'Competitors',
		itemName: 'Competitor',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.contactEmail, LIBRARY_HEADER_TYPES.contactPhone],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.contactEmail, LIBRARY_HEADER_TYPES.contactPhone],
		fields: '_id isActive name contactDetail',
		showEditOrder: true,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	userGroup: {
		key: 'userGroup',
		name: 'User Groups',
		itemName: 'User Group',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.teamLeader, LIBRARY_HEADER_TYPES.numUsers],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.teamLeader, LIBRARY_HEADER_TYPES.numUsers],
		fields: '_id name isActive teamLeader users',
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	marketing: {
		key: 'marketing',
		name: 'Marketing Items',
		itemName: 'Marketing Item',
		cardHeaders: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.contactEmail],
		headers: [LIBRARY_HEADER_TYPES.name, LIBRARY_HEADER_TYPES.contactEmail],
		fields: '_id isActive name contactDetail order documentType',
		showEditOrder: true,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	project: {
		key: 'project',
		name: 'Projects',
		itemName: 'Project',
		cardHeaders: [
			LIBRARY_HEADER_TYPES.projectRef,
			LIBRARY_HEADER_TYPES.projectName,
			LIBRARY_HEADER_TYPES.createdAt,
			LIBRARY_HEADER_TYPES.clients,
			LIBRARY_HEADER_TYPES.estimator,
			LIBRARY_HEADER_TYPES.netTotalChargeOut,
			LIBRARY_HEADER_TYPES.totalProfit,
			LIBRARY_HEADER_TYPES.totalMarginPercent,
		],
		headers: [
			LIBRARY_HEADER_TYPES.status,
			LIBRARY_HEADER_TYPES.projectRef,
			LIBRARY_HEADER_TYPES.projectName,
			LIBRARY_HEADER_TYPES.variations,
			LIBRARY_HEADER_TYPES.createdAt,
			LIBRARY_HEADER_TYPES.clients,
			LIBRARY_HEADER_TYPES.clientKeyContacts,
			LIBRARY_HEADER_TYPES.estimator,
			LIBRARY_HEADER_TYPES.winProbability,
			LIBRARY_HEADER_TYPES.netTotalChargeOut,
			LIBRARY_HEADER_TYPES.totalMarginPercent,
			LIBRARY_HEADER_TYPES.comments,
		],
		isSearch: true,
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	client: {
		key: 'client',
		name: 'Clients',
		itemName: 'Client',
		cardHeaders: [
			LIBRARY_HEADER_TYPES.clientName,
			LIBRARY_HEADER_TYPES.clientEmail,
			LIBRARY_HEADER_TYPES.phone,
			LIBRARY_HEADER_TYPES.contact,
			LIBRARY_HEADER_TYPES.city,
			LIBRARY_HEADER_TYPES.stateClient,
		],
		headers: [
			LIBRARY_HEADER_TYPES.clientName,
			LIBRARY_HEADER_TYPES.clientEmail,
			LIBRARY_HEADER_TYPES.phone,
			LIBRARY_HEADER_TYPES.contact,
			LIBRARY_HEADER_TYPES.city,
			LIBRARY_HEADER_TYPES.stateClient,
		],
		isSearch: true,
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: true,
		showGridToggle: true,
	},
	childrenClient: {
		key: 'childrenClient',
		name: 'Children Clients',
		itemName: 'Child Client',
		cardHeaders: [LIBRARY_HEADER_TYPES.clientName, LIBRARY_HEADER_TYPES.email, LIBRARY_HEADER_TYPES.state],
		headers: [LIBRARY_HEADER_TYPES.clientName, LIBRARY_HEADER_TYPES.email, LIBRARY_HEADER_TYPES.state],
		isSearch: true,
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
	company: {
		key: 'company',
		name: 'Companies',
		itemName: 'Company',
		cardHeaders: [LIBRARY_HEADER_TYPES.companyName, LIBRARY_HEADER_TYPES.companyEmail, LIBRARY_HEADER_TYPES.phone, LIBRARY_HEADER_TYPES.city, LIBRARY_HEADER_TYPES.state],
		headers: [LIBRARY_HEADER_TYPES.companyName, LIBRARY_HEADER_TYPES.companyEmail, LIBRARY_HEADER_TYPES.phone, LIBRARY_HEADER_TYPES.city, LIBRARY_HEADER_TYPES.state],
		isSearch: true,
		showEditOrder: false,
		showCompaniesAndParent: false,
		isBulkEdit: false,
		showGridToggle: true,
	},
};

const LIBRARY_COMPONENTS = {
	details: 'details',
	list: 'list',
};

const NAVIGATION_TYPES = {
	brand: 'brand',
	folder: 'folder',
	id: 'id',
	type: 'type',
	company: 'company',
};

const REFERENCE_CODE_TYPES = {
	additional_charge: {
		key: 'additional_charge',
		name: 'Additional Charges',
	},
	attachment_type: {
		key: 'attachment_type',
		name: 'Attachment Types',
	},
	divisions: {
		key: 'divisions',
		name: 'Divisions',
	},
	finish_type: {
		key: 'finish_type',
		name: 'Finish Types',
	},
	product_group: {
		key: 'product_group',
		name: 'Product Groups',
	},
	labour_type: {
		key: 'labour_type',
		name: 'Labour Types',
	},
	profile: {
		key: 'profile',
		name: 'Profiles',
	},
	quality_level: {
		key: 'quality_level',
		name: 'Quality Levels',
	},
	product_type: {
		key: 'product_type',
		name: 'Type',
	},
	substrate: {
		key: 'substrate',
		name: 'Substrates',
	},
	qualification: {
		key: 'qualification',
		name: 'Qualifications',
	},
};

export const MeasureToolDrawingsCategory = 'Measure Tool Drawings';

const REFERENCE_CODE_TYPES_ARRAY = [
	REFERENCE_CODE_TYPES.additional_charge,
	REFERENCE_CODE_TYPES.attachment_type,
	REFERENCE_CODE_TYPES.divisions,
	REFERENCE_CODE_TYPES.finish_type,
	REFERENCE_CODE_TYPES.product_group,
	REFERENCE_CODE_TYPES.labour_type,
	REFERENCE_CODE_TYPES.profile,
	REFERENCE_CODE_TYPES.quality_level,
	REFERENCE_CODE_TYPES.product_type,
	REFERENCE_CODE_TYPES.substrate,
	REFERENCE_CODE_TYPES.qualification,
];

const CHARGE_OUT_TYPES = {
	rate: 'rate',
	margin: 'margin',
	profit: 'profit',
};

const DISCOUNT_TYPES = {
	onCharges: 'onCharges',
	onProject: 'onProject',
	customCharges: 'customCharges',
};

export const DATE_FORMATS = {
	customMedium: 'MMM d, y, h:mm a',
	customMediumDate: 'MMM D, YYYY', // Oct 19, 2021
	dayMonthYear: 'dd/MM/yyyy',
	dayMonthYearFull: 'DD/MM/YYYY',
	dayMonth: 'dd/MM',
	displayDate: 'YYYY-MM-DD',
	hoursMinutes: 'h:mm a',
	mediumDate: 'mediumDate',
	shortDate: 'short',
	shortTime: 'shortTime',
	fullDate: 'fullDate',
	fullDateOrdered: 'EEEE, d MMMM y',
	timeTimezone: 'HH:mm:ss zzzz',
};

const COMMENT_TYPES = {
	inclusions: 'Inclusions',
	exclusions: 'Exclusions',
};

const svgIconPath = './assets/icons/';
const SVG_ICON_PATHS = {
	add: 'add',
	attachFile: 'attach-file',
	bookshelf: 'bookshelf',
	camera: 'camera',
	caret: 'caret',
	close: 'close',
	coatingSystem: 'coating-system',
	comment: 'comment',
	dashboard: 'dashboard',
	dots: 'dots',
	droplet: 'droplet',
	email: 'email',
	export: 'export',
	eye: 'eye',
	fileUpload: 'file-upload',
	folder: 'folder',
	grid: 'grid',
	insertFile: 'insert-file',
	internalNote: 'internal-note',
	list: 'list',
	logout: 'logout',
	monetization: 'monetization',
	magnifyingGlass: 'magnifying-glass',
	pen: 'pen',
	people: 'people',
	projectDescription: 'project-description',
	receipt: 'receipt',
	settings: 'settings',
	silhouettes: 'silhouettes',
	user: 'user',
	usherLogo: 'usher-logo',
	brush: 'brush',
	palette: 'palette',
	shoppingBasket: 'shopping-basket',
	silhouette: 'silhouette',
	localAtm: 'local-atm',
	localOffer: 'local-offer',
	panTool: 'pan-tool',
	download: 'download',
	edit: 'edit',
	adjust: 'adjust',
	textsms: 'textsms',
	star: 'star',
	starBorder: 'star-border',
	lens: 'lens',
	timer: 'timer',
	upload: 'upload',
	expandLess: 'expand_less',
	expandMore: 'expand_more',
};
Object.keys(SVG_ICON_PATHS).forEach(key => (SVG_ICON_PATHS[key] = svgIconPath + SVG_ICON_PATHS[key] + '.svg'));

export const FILES_FOLDERS_TYPES: { [key: string]: FileFolderType } = {
	tender_document: {
		key: 'tender_document',
		name: 'Tender Document',
		iconPath: SVG_ICON_PATHS.insertFile,
		pdfOutput: true,
	},
	quote_proposal: {
		key: 'quote_proposal',
		name: 'Quote Proposal',
		iconPath: SVG_ICON_PATHS.insertFile,
		pdfOutput: false,
	},
	office_folder: {
		key: 'office_folder',
		name: 'Office Folder',
		iconPath: SVG_ICON_PATHS.folder,
		pdfOutput: true,
	},
	site_folder: {
		key: 'site_folder',
		name: 'Site Folder',
		iconPath: SVG_ICON_PATHS.folder,
		pdfOutput: true,
	},
	safety_folder: {
		key: 'safety_folder',
		name: 'Safety Folder',
		iconPath: SVG_ICON_PATHS.folder,
		pdfOutput: false,
	},
	qa_folder: {
		key: 'qa_folder',
		name: 'QA Folder',
		iconPath: SVG_ICON_PATHS.folder,
		pdfOutput: false,
	},
	variation_quote: {
		key: 'variation_quote',
		name: 'Variation Quote',
		iconPath: SVG_ICON_PATHS.folder,
	},
	quote: {
		key: 'quote',
		name: 'Quote',
		iconPath: SVG_ICON_PATHS.folder,
	},
	boq: {
		key: 'boq',
		name: 'Bill of Quantity',
		iconPath: SVG_ICON_PATHS.folder,
	},
	product_purchasing: {
		key: 'product_purchasing',
		name: 'Product Purchasing',
		iconPath: SVG_ICON_PATHS.folder,
	},
	invoice: {
		key: 'invoice',
		name: 'Invoice',
		iconPath: SVG_ICON_PATHS.folder,
	},
};

const FILES_FOLDERS = [
	FILES_FOLDERS_TYPES.tender_document.key,
	FILES_FOLDERS_TYPES.quote_proposal.key,
	FILES_FOLDERS_TYPES.office_folder.key,
	FILES_FOLDERS_TYPES.site_folder.key,
	FILES_FOLDERS_TYPES.safety_folder.key,
	FILES_FOLDERS_TYPES.qa_folder.key,
];

// Generic function to return a Date object as a formatted string (DD/MM/YYYY HH:MM)
export const getFormattedDateTime = function (date: Date): string {
	return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
};

const createEmptyAddressDetail = function (): AddressDetail {
	return {
		addressLine1: undefined,
		addressLine2: undefined,
		addressLine3: undefined,
		city: undefined,
		state: undefined,
		region: undefined,
		postCode: undefined,
	};
};

const createEmptyContactDetail = function (): ContactDetail {
	return {
		website: undefined,
		email: undefined,
		phone: undefined,
		fax: undefined,
	};
};

const LIBRARY_OPTIONS = {
	brands: {
		name: LIBRARY_TYPES.brand.name,
		link: '/admin/brands',
		isAdminOnly: true,
	},
	products: {
		name: LIBRARY_TYPES.product.name,
		link: '/admin/products',
		isAdminOnly: true,
	},
	coatingSystems: {
		name: LIBRARY_TYPES.coatingSystem.name,
		link: '/admin/coating-systems',
		isAdminOnly: true,
	},
	colours: {
		name: LIBRARY_TYPES.colour.name,
		link: '/admin/colours',
		isAdminOnly: true,
	},
	measureTool: {
		name: LIBRARY_TYPES.measureTool.name,
		link: '/admin/measureTools',
		isAdminOnly: true,
	},
	filesFolders: {
		name: LIBRARY_TYPES.filesFolders.name,
		link: '/admin/files-folders',
		isAdminOnly: true,
	},
	incExcCommentTemplates: {
		name: LIBRARY_TYPES.incExcCommentTemplate.name,
		link: '/admin/incExcCommentTemplates',
		isAdminOnly: true,
	},
	rates: {
		name: LIBRARY_TYPES.rate.name,
		link: '/admin/labour-rates',
	},
	competitors: {
		name: LIBRARY_TYPES.competitor.name,
		link: '/admin/competitors',
		isAdminOnly: true,
	},
	suppliers: {
		name: LIBRARY_TYPES.supplier.name,
		link: '/admin/suppliers',
		isAdminOnly: true,
	},
	marketingItems: {
		name: LIBRARY_TYPES.marketing.name,
		link: '/admin/marketingitems',
		isAdminOnly: true,
	},
};

const COATING_SYSTEM_PERCENTAGES: Number[] = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10];

export enum ERROR_MESSAGES {
	atLeastOneApplicationMethod = 'Product should have at least one Application Method selected.',
	atLeastOnePurchaseOrderItem = 'Must add at least one purchase order item.',
	atLeastOneSize = 'Product should have at least one Size.',
	atLeastOneItem = 'Must have at least one Item.',
	couldNotDownload = 'Could not download the document.',
	duplicateEstimator = 'This user has already been added.',
	failedGenerate = 'Failed to generate document',
	invalidClient = 'Invalid client selected',
	missingEntity = 'Missing entity',
	noAttachment = 'No Attachment selected.',
	noClient = 'No Client selected.',
	noDocuments = 'No Documents selected.',
	passwordTooShort = 'Passwords must be at least 6 characters long.',
	passwordsDontMatch = 'Passwords do not match.',
	pleaseSelectCharge = 'Please select a Charge',
	pleaseSelectVariation = 'Please select a variation',
}

// Taken from https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingStatusCodes
export const GEOCODING_STATUSES = {
	ERROR: {
		key: 'ERROR',
		message: 'Google Maps Geocoding: Request timed out or there was a problem contacting the Google servers',
	},
	INVALID_REQUEST: {
		key: 'INVALID_REQUEST',
		message: 'Google Maps Geocoding: Invalid Request',
	},
	OK: { key: 'OK', message: '' },
	OVER_QUERY_LIMIT: {
		key: 'OVER_QUERY_LIMIT',
		message: 'Google Maps Geocoding: Exceeded API query limit.',
	},
	REQUEST_DENIED: {
		key: 'REQUEST_DENIED',
		message: 'Google Maps Geocoding: Request Denied',
	},
	UNKNOWN_ERROR: {
		key: 'UNKNOWN_ERROR',
		message: 'Google Maps Geocoding: Unknown Error',
	},
	ZERO_RESULTS: { key: 'ZERO_RESULTS', message: '' },
};

export enum INFO_MESSAGES {
	productColourCombinationAlreadyExists = 'That product / colour combination already exists.',
}

/**
 * List of All End-Points
 */
enum END_POINTS {
	approve = 'approve',
	brand = 'brand',
	budget = 'budget',
	Charge = 'charge',
	coatingSystem = 'coatingsystem',
	color = 'colour',
	competitor = 'competitor',
	division = 'division',
	filesFolder = 'files-folder',
	incExcCommentTemplate = 'inc-exc-comment-template',
	labourRate = 'labour-rate',
	marketing = 'marketing',
	product = 'product',
	projectTime = 'project-time',
	referenceCode = 'referencecode',
	saasAdminMessage = 'saas-admin-message',
	state = 'state',
	stripe = 'stripe',
	supplier = 'supplier',
	supplierProduct = 'supplier-product',
	userGroup = 'user-group',
	userTime = 'user-time',
	userTools = 'estimator-user-tool',
	salesReport = 'sales-report',
	productTours = 'product-tours',
}

const OVERTIME_RATES = [
	{ title: 'Time and half', value: 0.5 },
	{ title: 'Double', value: 1 },
	{ title: 'Shift work', value: 0.25 },
];

const CHARGE_CATEGORIES = {
	materials: 'Materials',
	overtimeHours: 'Overtime Hours',
	access: 'Access',
	ewp: 'Elevated Work Platform (EWP)',
	painting: 'Painting',
};

export enum LOGO_UPLOADER_TITLES {
	logo = 'Logo',
	profilePicture = 'Profile Picture',
	picture = 'Picture',
}

const INC_EXC_TITLES = {
	charCount: 'Char Count',
	excHeader: 'Exclusion Comments',
	favourite: 'Favourite',
	incHeader: 'Inclusion Comments',
	name: 'Name',
	action: 'Action',
	add: 'Add',
};

export const PROJECT_INDUSTRIES = {
	construction: {
		key: 'construction',
		label: 'Construction',
		subIndustries: [
			{
				key: 'architect',
				label: 'Architect',
			},
			{
				key: 'builderTier1',
				label: 'Builder Tier 1',
			},
			{
				key: 'builderTier2',
				label: 'Builder Tier 2',
			},
			{
				key: 'consultantsEngineers',
				label: 'Consultants/Engineers',
			},
		],
	},
	education: {
		key: 'education',
		label: 'Education',
		subIndustries: [
			{
				key: 'privateSchool',
				label: 'Private School',
			},
			{
				key: 'publicSchool',
				label: 'Public School',
			},
			{
				key: 'tertiary',
				label: 'Tertiary',
			},
			{
				key: 'university',
				label: 'University',
			},
			{
				key: 'childCare',
				label: 'Child Care',
			},
		],
	},
	healthCommunity: {
		key: 'healthCommunity',
		label: 'Health & Community',
		subIndustries: [
			{
				key: 'publicHospitals',
				label: 'Public Hospitals',
			},
			{
				key: 'privateHospitals',
				label: 'Private Hospitals',
			},
			{
				key: 'agedCare',
				label: 'Aged Care',
			},
			{
				key: 'retirementLiving',
				label: 'Retirement Living',
			},
			{
				key: 'medicalCentres',
				label: 'Medical Centres',
			},
			{
				key: 'religion',
				label: 'Religion',
			},
		],
	},
	sportsRecreation: {
		key: 'sportsRecreation',
		label: 'Sports & Recreation',
		subIndustries: [
			{
				key: 'gyms',
				label: 'Gyms',
			},
			{
				key: 'sportingClubs',
				label: 'Sporting Clubs',
			},
			{
				key: 'sportCentresGrounds',
				label: 'Sport Centres & Grounds',
			},
			{
				key: 'touristAttractions',
				label: 'Tourist Attractions',
			},
			{
				key: 'stadiums',
				label: 'Stadiums',
			},
		],
	},
	retail: {
		key: 'retail',
		label: 'Retail',
		subIndustries: [
			{
				key: 'shoppingCentres',
				label: 'Shopping Centres',
			},
			{
				key: 'fitOut',
				label: 'Fit-Out',
			},
			{
				key: 'restaurants',
				label: 'Restaurants',
			},
			{
				key: 'gaming',
				label: 'Gaming',
			},
			{
				key: 'pubs',
				label: 'Pubs',
			},
		],
	},
	property: {
		key: 'property',
		label: 'Property',
		subIndustries: [
			{
				key: 'commercialBuildings',
				label: 'Commercial Buildings',
			},
			{
				key: 'commercialRealEstate',
				label: 'Commercial Real estate',
			},
			{
				key: 'carParks',
				label: 'Car Parks',
			},
			{
				key: 'exhibitionConferenceCentres',
				label: 'Exhibition & Conference Centres',
			},
			{
				key: 'facilityManagers',
				label: 'Facility Managers',
			},
			{
				key: 'residentManagers',
				label: 'Resident Managers',
			},
		],
	},
	accommodation: {
		key: 'accommodation',
		label: 'Accommodation',
		subIndustries: [
			{
				key: 'hotelsResorts',
				label: 'Hotels & Resorts',
			},
			{
				key: 'motels',
				label: 'Motels',
			},
			{
				key: 'recreationParks',
				label: 'Recreation Parks',
			},
		],
	},
	strata: {
		key: 'strata',
		label: 'Strata',
		subIndustries: [
			{
				key: 'strataTitleManager',
				label: 'Strata title manager',
			},
			{
				key: 'bodyCorporateCommittee',
				label: 'Body Corporate committee',
			},
			{
				key: 'ownersCorp',
				label: 'Owners Corp',
			},
		],
	},
	industry: {
		key: 'industry',
		label: 'Industry',
		subIndustries: [
			{
				key: 'manufacturing',
				label: 'Manufacturing',
			},
			{
				key: 'foodProcessing',
				label: 'Food Processing',
			},
			{
				key: 'industrial',
				label: 'Industrial',
			},
			{
				key: 'transportFreight',
				label: 'Transport & Freight',
			},
		],
	},
	government: {
		key: 'government',
		label: 'Government',
		subIndustries: [
			{
				key: 'local',
				label: 'Local',
			},
			{
				key: 'state',
				label: 'State',
			},
			{
				key: 'federal',
				label: 'Federal',
			},
		],
	},
	notSpecified: {
		key: 'notSpecified',
		label: 'Not Specified',
		subIndustries: [
			{
				key: 'notSpecified',
				label: 'Not Specified',
			},
		],
	},
};
export const PROJECT_SUB_INDUSTRIES = {
	architect: {
		key: 'architect',
		label: 'Architect',
	},
	builderTier1: {
		key: 'builderTier1',
		label: 'Builder Tier 1',
	},
	builderTier2: {
		key: 'builderTier2',
		label: 'Builder Tier 2',
	},
	consultantsEngineers: {
		key: 'consultantsEngineers',
		label: 'Consultants/Engineers',
	},
	publicSchool: {
		key: 'publicSchool',
		label: 'Public School',
	},
	privateSchool: {
		key: 'privateSchool',
		label: 'Private School',
	},
	tertiary: {
		key: 'tertiary',
		label: 'Tertiary',
	},
	university: {
		key: 'university',
		label: 'University',
	},
	childCare: {
		key: 'childCare',
		label: 'Child Care',
	},
	publicHospitals: {
		key: 'publicHospitals',
		label: 'Public Hospitals',
	},
	privateHospitals: {
		key: 'privateHospitals',
		label: 'Private Hospitals',
	},
	agedCare: {
		key: 'agedCare',
		label: 'Aged Care',
	},
	retirementLiving: {
		key: 'retirementLiving',
		label: 'Retirement Living',
	},
	medicalCentres: {
		key: 'medicalCentres',
		label: 'Medical Centres',
	},
	religion: {
		key: 'religion',
		label: 'Religion',
	},
	gyms: {
		key: 'gyms',
		label: 'Gyms',
	},
	sportingClubs: {
		key: 'sportingClubs',
		label: 'Sporting Clubs',
	},
	sportCentresGrounds: {
		key: 'sportCentresGrounds',
		label: 'Sport Centres & Grounds',
	},
	touristAttractions: {
		key: 'touristAttractions',
		label: 'Tourist Attractions',
	},
	stadiums: {
		key: 'stadiums',
		label: 'Stadiums',
	},
	shoppingCentres: {
		key: 'shoppingCentres',
		label: 'Shopping Centres',
	},
	fitOut: {
		key: 'fitOut',
		label: 'Fit-Out',
	},
	restaurants: {
		key: 'restaurants',
		label: 'Restaurants',
	},
	gaming: {
		key: 'gaming',
		label: 'Gaming',
	},
	pubs: {
		key: 'pubs',
		label: 'Pubs',
	},
	commercialBuildings: {
		key: 'commercialBuildings',
		label: 'Commercial Buildings',
	},
	commercialRealEstate: {
		key: 'commercialRealEstate',
		label: 'Commercial Real estate',
	},
	carParks: {
		key: 'carParks',
		label: 'Car Parks',
	},
	exhibitionConferenceCentres: {
		key: 'exhibitionConferenceCentres',
		label: 'Exhibition & Conference Centres',
	},
	facilityManagers: {
		key: 'facilityManagers',
		label: 'Facility Managers',
	},
	residentManagers: {
		key: 'residentManagers',
		label: 'Resident Managers',
	},
	hotelsResorts: {
		key: 'hotelsResorts',
		label: 'Hotels & Resorts',
	},
	motels: {
		key: 'motels',
		label: 'Motels',
	},
	recreationParks: {
		key: 'recreationParks',
		label: 'Recreation Parks',
	},
	strataTitleManager: {
		key: 'strataTitleManager',
		label: 'Strata title manager',
	},
	bodyCorporateCommittee: {
		key: 'bodyCorporateCommittee',
		label: 'Body Corporate committee',
	},
	ownersCorp: {
		key: 'ownersCorp',
		label: 'Owners Corp',
	},
	manufacturing: {
		key: 'manufacturing',
		label: 'Manufacturing',
	},
	foodProcessing: {
		key: 'foodProcessing',
		label: 'Food Processing',
	},
	industrial: {
		key: 'industrial',
		label: 'Industrial',
	},
	transportFreight: {
		key: 'transportFreight',
		label: 'Transport & Freight',
	},
	local: {
		key: 'local',
		label: 'Local',
	},
	state: {
		key: 'state',
		label: 'State',
	},
	federal: {
		key: 'federal',
		label: 'Federal',
	},
	notSpecified: {
		key: 'notSpecified',
		label: 'Not Specified',
	},
};

const getExportMailSubject = (projectReference: number, projectName: string): string => {
	return `${projectReference} | ${projectName}`;
};

const getAcceptanceMailMessage = (projectReference: number, estimatorFullname: string, estimatorPhone: string): string => {
	return `Thank you for the opportunity to submit an estimate for this project, we look forward to the opportunity of working with you. We have assigned ${
		estimatorFullname || ''
	} our estimator to oversee the project, who will make contact with you shortly to go over the tender details.
The Usher Group are happy to consult on all aspects of the project including application methods, paint systems or even access solutions.
Should you have any questions or require additional information please do not hesitate to contact ${estimatorFullname || ''} on ${
		estimatorPhone || ''
	}. Please use the reference number # ${projectReference} when you call.`;
};

const getDeclineMailMessage = (): string => {
	return `Thank you for the opportunity to review your upcoming project. Due to our current workload we are unable to provide you with a complete Usher Group estimate and tender submission.
 If you are having difficulties obtaining trade coverage, please feel free to contact our office to discuss the matter further.
 If you have a detailed BOQ available, our team will gladly submit our rate structure to assist you.
 Thank you`;
};

const getExportMailMessage = (estimatorName: string, estimatorPosition?: string, estimatorPhoneNumber?: string): string => {
	let message = `

Kind Regards
${estimatorName}`;

	if (estimatorPosition) {
		message += `
${estimatorPosition || ''}`;
	}

	if (estimatorPhoneNumber) {
		message += `

${estimatorPhoneNumber || ''}`;
	}

	return message;
};

const CANT_ADD_TEAM_LEADER_MESSAGE: string = "The current team leader can't be added.";

const confirmModalMessage = confirmAction => `Are you sure you want to ${confirmAction} this item?`;

enum CONFIRM_ACTIONS {
	activate = 'activate',
	deactivate = 'deactivate',
	remove = 'remove',
}

export enum SHOW_HIDE {
	show = 'Show',
	hide = 'Hide',
}

export const documentTypes = {
	master: 'master',
	entity: 'entity',
};

export const SORT_BY_FIELD = {
	createdAt: {
		key: 'createdAt',
		label: 'Date ',
	},
	grossTotalChargeOut: {
		key: 'grossTotalChargeOut',
		label: 'Price',
	},
};

export enum CALCULATION_TYPES {
	cost = 'calculateCost',
	lumpSum = 'lumpSumCost',
	supplier = 'supplierProductCost',
}

const CLONE_PROJECT_FIELDS: { [key: string]: boolean } = {
	company: true,
	leadReference: true,
	estimator: true,
	siteContact: true,
	addressDetail: true,
	billingAddressDetail: true,
	deliveryAddressDetail: true,
	materialMarkUp: true,
	wasteToConsumables: true,
	gst: true,
	discount: true,
	discountPercentage: true,
	purchaseOrderNumber: true,
	probability: true,
	discountType: true,
	isDiscountDisabled: true,
	division: true,
	marketing: true,
	salesPerson: true,
	projectManager: true,
	siteEmail: true,
	sitePhone: true,
	paymentTerms: true,
	isEbaRates: true,
	isDiscountPercentage: true,
	isDeliveryAddressSame: true,
	industry: true,
	subIndustry: true,
	defaultChargeCategory: true,
	labourType: true,
	contractAdministrator: true,
};

const CUSTOM_SEARCH_TEXT = 'Search for:';
const ACTIVE_TIME_UPDATE_RATE: number = 300000; // 5 minutes in milliseconds
const NO_DECIMAL_CURRENCY_PIPE_OPTION: string = '1.0-0';

export const TIMES_IN_MILLISECONDS = {
	fifteenMinutes: 900000,
	fiveMinutes: 300000,
	threeMinutes: 180000,
};

// A distance value a location can have set to indicate it was not found.
// Value must be greater than the circumference of the earth (in Km) so
// therefore can never be obtained normally. Necessary as a way to identify
// not found addresses and to sort said addresses as being the 'furthest'.
export const notFoundLocationDistance = 99999;

export const MAX_FILE_SIZE: number = 1000000000;

const CHART_LABELS = {
	quoted: 'Quoted',
};

const ROUTE_LINKS = {
	admin: 'admin',
	alerts: 'alerts',
	checkout: 'checkout',
	client: 'client',
	clients: 'clients',
	dashboard: '',
	demo: 'demo',
	estimatorUserTool: 'estimator-user-tool',
	export: 'export',
	forgotPassword: 'forgotpassword',
	help: 'https://intercom.help/paintprojex',
	libraries: 'libraries',
	login: 'login',
	measuringTool: 'measuring-tool',
	newProjects: 'new-projects',
	renewSubscription: 'renew-subscription',
	renewSubscriptionHistory: 'renew-subscription/history',
	project: 'project',
	projectSearch: 'projects',
	subscription: 'subscription',
	subscriptionHistory: 'subscription/history',
	user: 'user',
	userTools: 'user-tools',
	createProject: 'create-project',
};

export const countriesArray = environment.countries;

export const top5ClientsOptions = [
	'Top 5 Clients Profit Won',
	'Top 5 Clients Projects Number Won',
	'Top 5 Clients Projects Value Won',
	'Lowest 5 Clients Profit Won',
	'Lowest 5 Clients Projects Number Won',
	'Lowest 5 Clients Projects Value Won',
];

export enum CUSTOM_CHART_TYPES {
	roundedBar = 'roundedBar',
}

export const NavigationMenuItems = {
	clients: TEXT.clients,
	dashboard: TEXT.dashboard,
	library: TEXT.library,
	newProject: TEXT.newProject,
	projects: TEXT.projects,
	reports: TEXT.reports,
	scheduling: 'Scheduling',
	invoices: 'Invoices',
	subscription: TEXT.subscription,
};

export const NavigationMenuOptionItems = {
	brands: TEXT.brands,
	coatingSystems: TEXT.coatingSystems,
	colours: TEXT.colours,
	companies: TEXT.companies,
	competitors: TEXT.competitors,
	filesFolders: TEXT.filesFolders,
	importData: TEXT.importData,
	inclusionsExclusions: TEXT.inclusionsExclusions,
	labourRates: TEXT.labourRates,
	products: TEXT.products,
	reports: TEXT.reports,
	suppliers: TEXT.suppliers,
	support: TEXT.support,
	tasks: TEXT.tasks,
	marketingItems: TEXT.marketingItems,
	userGroups: TEXT.userGroups,
	users: TEXT.users,
};

export const STRIPE_CUSTOMER_URL = 'https://dashboard.stripe.com/customers';

export class Constants {
	public static ALERT_STATUS = ALERT_STATUS;
	public static APPLICATION_METHODS = APPLICATION_METHODS;
	public static APPLICATION_METHODS_ARRAY: Array<ApplicationMethod> = Object.values(APPLICATION_METHODS);
	public static BASE_API_URL: string = BASE_API_URL;
	public static BASE_AVATAR_URL: string = BASE_AVATAR_URL;
	public static BASE_FRONTEND_URL: string = BASE_FRONTEND_URL;
	public static BOQ_COMPONENT_FIELD_MESSAGE: string = BOQ_COMPONENT_FIELD_MESSAGE;
	public static CHARGE_CATEGORIES = CHARGE_CATEGORIES;
	public static CHARGE_OUT_TYPES = CHARGE_OUT_TYPES;
	public static CHARGE_TYPES: { Boq: string; AdditionalCharge: string } = CHARGE_TYPES;
	public static COATING_SYSTEM_PERCENTAGES = COATING_SYSTEM_PERCENTAGES;
	public static COMMENT_TYPES = COMMENT_TYPES;
	public static CURRENT_SESSION_BEGIN: string = CURRENT_SESSION_BEGIN;
	public static CUSTOM_SEARCH_TEXT = CUSTOM_SEARCH_TEXT;
	public static DATE_FORMATS = DATE_FORMATS;
	public static DEFAULT_PAYMENT_TERM_DAYS: string = DEFAULT_PAYMENT_TERM_DAYS;
	public static DISCOUNT_TYPES = DISCOUNT_TYPES;
	public static DOCUMENT_GENERATED: string = DOCUMENT_GENERATED;
	public static END_POINTS = END_POINTS;
	public static ENVIRONMENTS: Array<string> = ENVIRONMENTS;
	public static EDIT_PROJECT_STATUS_LABELS: typeof EDIT_PROJECT_STATUS_LABELS = EDIT_PROJECT_STATUS_LABELS;
	public static FILE_TOO_BIG_MESSAGE: string = FILE_TOO_BIG_MESSAGE;
	public static FILES_FOLDERS = FILES_FOLDERS;
	public static FILES_FOLDERS_TYPES = FILES_FOLDERS_TYPES;
	public static FORM_STATES = FORM_STATES;
	public static HEIGHT_RANGES = HEIGHT_RANGES;
	public static IE_DISABLED_MESSAGE: string = IE_DISABLED_MESSAGE;
	public static INC_EXC_TITLES = INC_EXC_TITLES;
	public static LEAD_REFERENCES = LEAD_REFERENCES;
	public static LEAD_REFERENCES_US = LEAD_REFERENCES_US;
	public static LIBRARY_COMPONENTS = LIBRARY_COMPONENTS;
	public static LIBRARY_HEADER_TYPES = LIBRARY_HEADER_TYPES;
	public static LIBRARY_TYPES = LIBRARY_TYPES;
	public static LOCAL_STORAGE_EMAIL_KEY: string = LOCAL_STORAGE_EMAIL_KEY;
	public static LOCAL_STORAGE_EXPIRES_AT_KEY: string = LOCAL_STORAGE_EXPIRES_AT_KEY;
	public static LOCAL_STORAGE_FIRSTNAME_KEY: string = LOCAL_STORAGE_FIRSTNAME_KEY;
	public static LOCAL_STORAGE_LASTNAME_KEY: string = LOCAL_STORAGE_LASTNAME_KEY;
	public static LOCAL_STORAGE_RECENT_PRODUCTS_KEY: string = LOCAL_STORAGE_RECENT_PRODUCTS_KEY;
	public static LOCAL_STORAGE_ROLE_KEY: string = LOCAL_STORAGE_ROLE_KEY;
	public static LOCAL_STORAGE_PHONE_KEY: string = LOCAL_STORAGE_PHONE_KEY;
	public static LOCAL_STORAGE_TOKEN_KEY: string = LOCAL_STORAGE_TOKEN_KEY;
	public static LOCAL_STORAGE_USER_PICTURE: string = LOCAL_STORAGE_USER_PICTURE;
	public static LOCAL_STORAGE_USERID_KEY: string = LOCAL_STORAGE_USERID_KEY;
	public static LOG_OUT_TO_SEE_CHANGES: string = LOG_OUT_TO_SEE_CHANGES;
	public static ENTITY_USERS_LOG_OUT_TO_SEE_CHANGES: string = ENTITY_USERS_LOG_OUT_TO_SEE_CHANGES;
	public static MEASUREMENTS: Array<string> = MEASUREMENTS;
	public static measurementAbbreviation = measurementAbbreviation;
	public static NAVIGATION_TYPES = NAVIGATION_TYPES;
	public static NO_FILE_CHOSEN: string = NO_FILE_CHOSEN;
	public static OVERTIME_RATES = OVERTIME_RATES;
	public static PAGINATE_ITEMS_5PER_PAGE: number = PAGINATE_ITEMS_5PER_PAGE;
	public static PAGINATE_ITEMS_10PER_PAGE: number = PAGINATE_ITEMS_10PER_PAGE;
	public static PAGINATE_ITEMS_25PER_PAGE: number = PAGINATE_ITEMS_25PER_PAGE;
	public static PAGINATE_ITEMS_50PER_PAGE: number = PAGINATE_ITEMS_50PER_PAGE;
	public static PASSWORD_MATCH_MESSAGE: string = PASSWORD_MATCH_MESSAGE;
	public static PDF_ONLY_MESSAGE: string = PDF_ONLY_MESSAGE;
	public static PERIOD_TYPES = PERIOD_TYPES;
	public static PLEASE_ALLOW_POPUP: string = PLEASE_ALLOW_POPUP;
	public static PROFILE_DEFAULT_PICTURE: string = PROFILE_DEFAULT_PICTURE;
	public static PROJECT_CALCULATION_FIELDS = PROJECT_CALCULATION_FIELDS;
	public static PROJECT_INDUSTRIES = PROJECT_INDUSTRIES;
	public static PROJECT_STATUS = PROJECT_STATUS;
	public static PROJECT_STATUS_ARRAY = PROJECT_STATUS_ARRAY;
	public static PROJECT_SUB_INDUSTRIES = PROJECT_SUB_INDUSTRIES;
	public static VARIATION_PROJECT_STATUS_ARRAY = VARIATION_PROJECT_STATUS_ARRAY;
	public static PROJECT_STATUS_VALUE_ARRAY = PROJECT_STATUS_VALUE_ARRAY;
	public static PROJECT_STATUS_REASONS = PROJECT_STATUS_REASONS;
	public static PROJECT_STATUS_STYLE_BG = PROJECT_STATUS_STYLE_BG;
	public static PROJECT_STATUS_STYLE_FILL = PROJECT_STATUS_STYLE_FILL;
	public static REFERENCE_CODE_TYPES = REFERENCE_CODE_TYPES;
	public static REFERENCE_CODE_TYPES_ARRAY = REFERENCE_CODE_TYPES_ARRAY;
	public static SAVE_CHARGES_FIRST = SAVE_CHARGES_FIRST;
	public static SELECT_DETAILS_MESSAGE: string = SELECT_DETAILS_MESSAGE;
	public static SUPPLIER_TYPES = SUPPLIER_TYPES;
	public static SVG_ICON_PATHS = SVG_ICON_PATHS;
	public static TARGET_RATES_TYPES = TARGET_RATES_TYPES;
	public static TARGET_RATES_TITLES = TARGET_RATES_TITLES;
	public static TASK_ACTIONS = TASK_ACTIONS;
	public static TASK_TYPES = TASK_TYPES;
	public static TERM_DAYS: Array<string> = TERM_DAYS;
	public static USER_ROLES: {
		admin: UserRole;
		user: UserRole;
		trainee: UserRole;
	} = USER_ROLES;
	public static VALIDATION_ERROR_UNIQUE_CHARGE_OUT_RATE: string = VALIDATION_ERROR_UNIQUE_CHARGE_OUT_RATE;
	public static VALIDATION_ERROR_UNIQUE_COMPANIES: string = VALIDATION_ERROR_UNIQUE_COMPANIES;
	public static VALIDATION_ERROR_UNIQUE_LABOUR_COSTS: string = VALIDATION_ERROR_UNIQUE_LABOUR_COSTS;
	public static STRIPE_CUSTOMER_URL: string = STRIPE_CUSTOMER_URL;

	public static ONLY_ADMINS_MESSAGE: string = ONLY_ADMINS_MESSAGE;
	public static createEmptyAddressDetail = createEmptyAddressDetail;
	public static createEmptyContactDetail = createEmptyContactDetail;

	public static LIBRARY_OPTIONS = LIBRARY_OPTIONS;

	public static MODAL_STYLE = MODAL_STYLE;
	public static MODAL_TEXT = MODAL_TEXT;
	public static MODAL_TITLE = MODAL_TITLE;
	public static MODAL_MESSAGE = MODAL_MESSAGE;
	public static CANT_ADD_TEAM_LEADER_MESSAGE = CANT_ADD_TEAM_LEADER_MESSAGE;

	public static getAcceptanceMailMessage = getAcceptanceMailMessage;
	public static getDeclineMailMessage = getDeclineMailMessage;
	public static getExportMailSubject = getExportMailSubject;
	public static getExportMailMessage = getExportMailMessage;
	public static confirmModalMessage = confirmModalMessage;
	public static CONFIRM_ACTIONS = CONFIRM_ACTIONS;
	public static ACTIVE_TIME_UPDATE_RATE = ACTIVE_TIME_UPDATE_RATE;
	public static CLONE_PROJECT_FIELDS = CLONE_PROJECT_FIELDS;
	public static NO_DECIMAL_CURRENCY_PIPE_OPTION = NO_DECIMAL_CURRENCY_PIPE_OPTION;
	public static CHART_LABELS = CHART_LABELS;
	public static ROUTE_LINKS = ROUTE_LINKS;

	// ** Validation ** //
	public static VALIDATION_ERROR_REQUIRED = (a): string => {
		return `'${a}' is required. `;
	};
	public static VALIDATION_ERROR_MAXLENGTH = (a, b): string => {
		return `'${a}' must be less than ${b} characters. `;
	};
	public static VALIDATION_ERROR_MINLENGTH = (a, b): string => {
		return `'${a}' must have at least ${b} characters. `;
	};
	public static VALIDATION_ERROR_MINLENGTHARRAY = (a, b): string => {
		return `'${a}' must have at least ${b} entry. `;
	};
	// public static VALIDATION_ERROR_PATTERN = (a): string => {
	//   return `Property '${a}' has an invalid pattern.`;
	// };
	public static VALIDATION_ERROR_MAX = (a, b): string => {
		return `'${a}' must be less than ${b}. `;
	};
	public static VALIDATION_ERROR_MIN = (a, b): string => {
		// If number is not whole integer use greater than or equal to text otherwise assume we're using whole numbers
		if (typeof b === 'number' && !Number.isInteger(b)) {
			return `'${a}' must be greater than or equal to ${b}. `;
		} else {
			return `'${a}' must be greater than ${b - 1}. `;
		}
	};
	// public static VALIDATION_ERROR_REQUIREDTRUE = (a): string => {
	//   return `Property '${a}' must be true.`;
	// };
	public static VALIDATION_ERROR_EMAIL = (a): string => {
		return `Please enter a valid '${a}'. `;
	};
	public static VALIDATION_ERROR_ARRAYUNIQUE = (a): string => {
		return `'${a}' must be unique. `;
	};
	public static VALIDATION_ERROR_WHITESPACE = (a): string => {
		return `'${a}' cannot be blank. `;
	};

	// public static VALIDATION_ERROR_NULLVALIDATOR = (a): string => {
	//   return `Property '${a}' cannot be null.`;
	// };

	public static getFileMimeType(extension: string) {
		if (extension) {
			switch (extension) {
				case 'csv':
					return FileMimeTypeEnum.csv;
				case 'doc':
					return FileMimeTypeEnum.doc;
				case 'docx':
					return FileMimeTypeEnum.docx;
				case 'jpg':
					return FileMimeTypeEnum.jpg;
				case 'jpeg':
					return FileMimeTypeEnum.jpeg;
				case 'png':
					return FileMimeTypeEnum.png;
				case 'pdf':
					return FileMimeTypeEnum.pdf;
				case 'svg':
					return FileMimeTypeEnum.svg;
				case 'txt':
					return FileMimeTypeEnum.txt;
				case 'ppt':
					return FileMimeTypeEnum.ppt;
				case 'pptx':
					return FileMimeTypeEnum.pptx;
				case 'xls':
					return FileMimeTypeEnum.xls;
				case 'xlsx':
					return FileMimeTypeEnum.xlsx;
				case 'xml':
					return FileMimeTypeEnum.xml;
				case 'ods':
					return FileMimeTypeEnum.ods;
				case 'odp':
					return FileMimeTypeEnum.odp;
				case 'odt':
					return FileMimeTypeEnum.odt;
				case 'pub':
					return FileMimeTypeEnum.pub;
			}
		}
	}
}

export enum months {
	january,
	february,
	march,
	april,
	may,
	june,
	july,
	august,
	september,
	october,
	november,
	december,
}

export const SalesText = {
	allCompanies: 'all companies',
	allDivision: 'all division',
	allUsers: 'all users',
	company: 'company',
	createReport: 'create report',
	division: 'division',
	estimators: 'estimators',
	endDate: 'end date',
	generate: 'generate',
	leadSource: 'lead source',
	preview: 'preview',
	previewMock: 'preview (MOCK)',
	probability: 'probability',
	salesDashboard: 'sales dashboard',
	salesReport: 'sales report',
	startAndFinishDates: 'start and finish dates',
	startDate: 'start date',
	user: 'user',
	winLoss: 'win loss',
	liveOpportunities: 'live opportunities',
	createdFromTo: 'created from - to',
	quoteActual: 'quote actual',
	quoteTarget: 'quote target',
	salesWon: 'sales won',
	salesTarget: 'sales target',
	grossProfitActual: 'gross profit actual',
	grossProfitTarget: 'gross profit target',
	salesReporting: 'sales reporting',
};
