import { Component, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MeasureSystemService } from '../../../services/measure-system.service';
import { NumberHelper } from '../../../helpers';

/**
 * Lookup table for converting any area unit to square metres.
 * Values represent how many m² are in one of these units.
 */
const UNIT_CONVERSIONS: { [unit: string]: number } = {
  // LENGTH
  mm: 0.001,
  cm: 0.01,
  m: 1,
  lm: 1,
  km: 1000,
  in: 0.0254,
  ft: 0.3048,
  lft: 0.3048,
  yd: 0.9144,
  mi: 1609.344,

  // AREA
  sqmm: 1e-6,
  sqcm: 1e-4,
  sqm: 1,
  sqkm: 1e6,
  are: 100,
  hectare: 10000,
  sqin: 0.00064516,
  sqft: 0.092903,
  sqyd: 0.83612736,
  acre: 4046.8564224,
  sqmi: 2.59e6,

  invsqft: 10.7639,
  invft: 3.28084, // Reciprocal of 0.3048 (meters to feet)
  invin: 39.3701, // Reciprocal of 0.0254 (meters to inches)
  invlft: 3.28084, // Reciprocal of 0.3048 (lineal metres to lineal feet)

  litre: 1,
  gallon: 3.78541,

  'sqm/litre': 1,
  'sqft/gallon': 0.092903 / 3.78541,
};


@Component({
  selector: 'metric-imperial-input',
  standalone: true,
  imports: [],
  templateUrl: './metric-imperial-input.component.html',
  styleUrl: './metric-imperial-input.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetricImperialInputComponent),
      multi: true,
    }
  ]
})
export class MetricImperialInputComponent implements ControlValueAccessor {
    @Input() metricUnit: string;
    @Input() imperialUnit: string;
    @Input() disabled: boolean;
    @Input() min?: number;
    @Input() max?: number;
    @Input() precision?: number;
    @Output() blur: EventEmitter<any> = new EventEmitter();

    // The internally stored value (always in metric)
    private internalValue = 0;
    private onChangeFn: (val: number) => void = () => {};
    private onTouchedFn: () => void = () => {};
    protected isEditing: boolean = false;
    protected editingValue = '';

    constructor(private measureSystemService: MeasureSystemService) {}

    get displayValue(): number {
      if (this.showImperialValue()) {
        return this.applyRoundingAndClamping(this.internalValue / UNIT_CONVERSIONS[this.imperialUnit]);
      } else {
        return this.applyRoundingAndClamping(this.internalValue);
      }
    }

  /**
   * This method is called whenever the user changes the displayed input value.
   * We convert the imperial unit back to metric, and propagate that metric value to the form.
   * @param value the value of the input
   */
  onBlur(value: string) {
    const val = parseFloat(value);

    if (!isNaN(val)) {
      this.internalValue = this.showImperialValue() ? val * UNIT_CONVERSIONS[this.imperialUnit] : val;
    } else {
      this.internalValue = 0;
    }

    this.onChangeFn(this.internalValue);
    this.blur.emit(this.internalValue);
    this.isEditing = false;
  }

  onInput(val: string) {
    this.editingValue = val;
  }

  /**
   * Called by Angular when a new value is written to the from control programatically
   * This value should always be in metric (because the form control stores all values in metric internally)
   * @param value
   */
  writeValue(value: number): void {
    if (NumberHelper.isNumber(value)) {
      this.internalValue = value;
      this.editingValue = this.displayValue.toString();
    } else {
      this.internalValue = 0;
      this.editingValue = '0';
    }
  }

  /**
   * Registers an outside (parent form) onChange function so we can call it whenever our input is updated.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  /**
   * Registers an outside (parent form) onTouched function that gets called when our form control is touched.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private showImperialValue(): boolean {
    return this.measureSystemService.preferredSystem.value === 'imperial';
  }

  private applyRoundingAndClamping(value: number): number {
    if (NumberHelper.isNumber(this.min) || NumberHelper.isNumber(this.max)) {
      value = NumberHelper.clamp(value, this.min ?? -Infinity, this.max ?? Infinity);
    }

    if (NumberHelper.isNumber(this.precision)) {
      value = parseFloat(value.toFixed(this.precision));
    }

    return value;
  }
}
