import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { DecimalPipe } from '@angular/common';
import { MeasureSystemService } from '../services/measure-system.service';

@Pipe({
	name: 'area',
	pure: false,
	standalone: true,
})
export class AreaPipe implements PipeTransform {

	constructor(private measureSystemService: MeasureSystemService) {}

	/**
	 * Lookup table for converting any area unit to square metres.
	 *
	 * Values represent how many square metres are in one of these units.
	 * For example:
	 *  - 1 sqcm = 0.0001 sqm
	 *  - 1 sqft = 0.092903 sqm
	 * @private
	 */
	private readonly lookup: { [unit: string]: number } = {
		// Metric
		sqmm: 1e-6,     	// 1 mm² = 1e-6 m²
		sqcm: 1e-4,     	// 1 cm² = 1e-4 m²
		sqm: 1,         	// 1 m²  = 1 m²
		sqkm: 1e6,      	// 1 km² = 1e6 m²
		are: 100,       	// 1 are = 100 m²
		hectare: 10000, 	// 1 ha  = 10000 m²

		// Imperial/US
		sqin: 0.00064516,    // 1 in²  = ~0.00064516 m²
		sqft: 0.092903,      // 1 ft²  = ~0.092903 m²
		sqyd: 0.83612736,    // 1 yd²  = ~0.83612736 m²
		acre: 4046.8564224,  // 1 acre = ~4046.8564224 m²
		sqmi: 2.59e6         // 1 mi²  = ~2.59e6 m²
	};

	transform(value: number, from: string, toMetric: string, toImperial: string, system?: string): number {
		// ensure that 'value' is a real number
		if (typeof value !== 'number' || isNaN(value)) {
			return 0;
		}

		// if value is 0, the result will be 0
		if (value === 0) {
			return 0;
		}

		// if not measure system was provided, use the user's preferred system
		if (!system) {
			system = this.measureSystemService.preferredSystem.value;
		}

		// set preferred output based on system or user preference
		const to = system === 'imperial' ? toImperial : toMetric;

		// if fromUnit/metricUnit/imperialUnit are not given or are the same, just return the input value
		if (!from || !to || from === to) {
			return value;
		}

		// lookup conversion factor for selected units
		const fromFactor = this.lookup[from.toLowerCase()];
		const toFactor = this.lookup[to.toLowerCase()];

		// throw error if we don't recognise the units
		if (!fromFactor || !toFactor) {
			throw new Error(`Unrecognized area unit: from=${from} to=${to}`);
		}

		// convert value (from) -> sqm -> target unit
		return (value * fromFactor) / toFactor;
	}
}

/**
 * Wrapper Pipe of the AreaPipe that appends the preferred unit of measurement to the output
 * This is separated from the AreaPipe so that AreaPipe can be used for numerical conversions
 */
@Pipe({
	name: 'areaWithUnit',
	pure: false,
	standalone: true,
})
export class AreaUnitPipe implements PipeTransform {

	constructor(private measureSystemService: MeasureSystemService, private decimal: DecimalPipe) {}

	private readonly lookup: { [unit: string]: string } = {
		// Metric
		sqmm: 'mm²',
		sqcm: 'cm²',
		sqm: 'm²',
		sqkm: 'km²',
		are: 'are',
		hectare: 'ha',

		// Imperial/US
		sqin: 'in²',
		sqft: 'ft²',
		sqyd: 'yd²',
		acre: 'acre',
		sqmi: 'mi²',
	};


	transform(value: number, from: string, toMetric: string, toImperial: string, format?: string): string {
		// set preferred output based on system or user preference
		const system = this.measureSystemService.preferredSystem.value;
		const to = system === 'imperial' ? toImperial : toMetric;
		const area = new AreaPipe(this.measureSystemService);
		const unit = this.lookup[to];
		const raw = area.transform(value, from, toMetric, toImperial, system);

		return `${this.decimal.transform(raw, format)} ${unit}`;
	}
}
