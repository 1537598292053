import { Injectable } from '@angular/core';

import { ChargesFilter } from '../dialog/components/charges/common/charges.interfaces';
import { Constants, LOCAL_STORAGE_KEYS } from '../helpers';
import { PartialUser, ProjectSearch } from '../interfaces';
import { AuthUserModel, UserModel } from '../models';
import { EstimatorUserService } from './estimator-user/estimator-user.service';

@Injectable()
export class LocalStorageService {
	public previousSessionUser: PartialUser = {
		email: '',
		firstName: '',
		id: '',
		lastName: '',
	};

	private cachedData = {};
	private localStorageKeys: typeof LOCAL_STORAGE_KEYS = LOCAL_STORAGE_KEYS;

	constructor(private estimatorUserService: EstimatorUserService) {}

	/**
	 *  Add Product Id to the Local storage and cacheData
	 *  limit of 10 unique product Id
	 * @param {string} productId
	 */
	public addRecentProduct(productId: string): void {
		let products = this.getCachedKey(Constants.LOCAL_STORAGE_RECENT_PRODUCTS_KEY);

		if (!products) {
			products = [];
		}
		if (products.length >= 10) {
			products.pop();
		}

		//Remove duplicate Product
		products = products.filter(item => item !== productId);
		products.unshift(productId);

		this.setCachedKey(this.localStorageKeys.recentProducts, products);
	}

	public clearCurrentSessionTimeStart(): void {
		localStorage.removeItem(this.localStorageKeys.currentSession);
	}

	public clearPreviousUserDetails(): void {
		localStorage.removeItem(this.localStorageKeys.previousUser);
	}

	public clearUserDetails(): void {
		localStorage.removeItem(this.localStorageKeys.userDetails);
	}

	/**
	 * Get chargesFilter object to be able to populate fields on return to the same page.
	 */
	public getChargesFilter(): ChargesFilter {
		const chargesFilter = localStorage.getItem(this.localStorageKeys.chargesFilter);

		return chargesFilter ? JSON.parse(chargesFilter) : undefined;
	}

	public clearProjectSearch(): void {
		localStorage.removeItem(this.localStorageKeys.projectSearch);
	}

	public getSessionBegin(): string {
		return JSON.parse(localStorage.getItem(this.localStorageKeys.currentSession));
	}

	public getPreviousUser(): PartialUser {
		this.previousSessionUser = JSON.parse(localStorage.getItem(this.localStorageKeys.previousUser));

		if (this.previousSessionUser) {
			return this.previousSessionUser;
		} else {
			return undefined;
		}
	}

	public getProjectSearch(): ProjectSearch {
		const projectSearch = localStorage.getItem(this.localStorageKeys.projectSearch);

		return projectSearch ? JSON.parse(projectSearch) : undefined;
	}

	/**
	 * Get the CacheDate For LOCAL_STORAGE_RECENT_PRODUCTS_KEY
	 * @returns {undefined | any | any}
	 */
	public getRecentProducts(): string[] {
		return this.getCachedKey(this.localStorageKeys.recentProducts);
	}

	public getUserDetails(): AuthUserModel {
		const userDetails: AuthUserModel = new AuthUserModel(JSON.parse(localStorage.getItem(this.localStorageKeys.userDetails)));

		if (userDetails) {
			if (!this.estimatorUserService.hasUser) {
				this.estimatorUserService.setUserFromAuth(userDetails);
			}
			this.setPreviousSessionUser(userDetails);

			// userDetails.preferredMeasureSystem = 'metric';

			return userDetails;
		} else {
			return undefined;
		}
	}

	public getUserId(): string {
		const user = this.getUserDetails();

		return user && user.id ? user.id : undefined;
	}

	public getUserKey(property: string) {
		const user = this.getUserDetails();

		return user && user[property] ? user[property] : undefined;
	}

	/**
	 * Removes chargesFilter object
	 */
	public removeChargesFilter(): void {
		localStorage.removeItem(this.localStorageKeys.chargesFilter);
	}

	/**
	 * Removes project search fromLocal Storage
	 */
	public removeProjectSearch(): void {
		localStorage.removeItem(this.localStorageKeys.projectSearch);
	}

	/**
	 * Stores strigified chargesFilter object to be able to populate fields on return to the same page.
	 * @param chargesFilter
	 */
	public setChargesFilter(chargesFilter: ChargesFilter): void {
		localStorage.setItem(this.localStorageKeys.chargesFilter, JSON.stringify(chargesFilter));
	}

	public setCurrentSessionTimeStart(): void {
		localStorage.setItem(this.localStorageKeys.currentSession, JSON.stringify(new Date()));
	}

	/**
	 * Stores strigified project search fields and project search display values to be able to populate fields on return to the same page.
	 * @param projectSearch
	 */
	public setProjectSearch(projectSearch: ProjectSearch): void {
		localStorage.setItem(this.localStorageKeys.projectSearch, JSON.stringify(projectSearch));
	}

	/**
	 * Write the key and value to local storage
	 * Store the key and value into the cachedData
	 * @param key
	 * @param value
	 */
	public setUserKey(key, value): void {
		const user = this.getUserDetails();
		user[key] = value;
		this.setUserDetails(user as AuthUserModel);
	}

	/**
	 * Write the key and value to local storage
	 * Store the key and value into the cachedData
	 * @param key
	 * @param value
	 */
	public setCachedKey(key, value): void {
		this.cachedData[key] = value;
		localStorage.setItem(key, JSON.stringify(value));
	}

	public setUserDetails(user: AuthUserModel): void {
		localStorage.setItem(this.localStorageKeys.userDetails, JSON.stringify(user));
		this.estimatorUserService.setUserFromAuth(this.getUserDetails());
	}

	public updateSessionUser(userUpdate: UserModel): void {
		const user = this.getUserDetails();
		if (userUpdate) {
			user.firstName = userUpdate.firstName;
			user.lastName = userUpdate.lastName;
			user.email = userUpdate.email;
			user.phone = userUpdate.phone;
			user.role = userUpdate.role;
			user.supplierLocationFavourites = userUpdate.supplierLocationFavourites;
			user.newMeasureTool = userUpdate.newMeasureTool;
			user.language = userUpdate.language;
		}
		this.setUserDetails(user);
	}

	/**
	 * Get the value from cachedData
	 * Get the value from localStorage if it doesn't exist in cachedData
	 * Store it in cachedData if it doesn't exist
	 * @param key
	 * @returns {any}
	 */
	private getCachedKey(key) {
		if (!this.cachedData[key]) {
			this.cachedData[key] = localStorage.getItem(key);
		}
		if (!this.cachedData[key]) {
			return undefined;
		}
		try {
			return JSON.parse(this.cachedData[key]);
		} catch (e) {
			// Error will be thrown when parsing a non object value
			return this.cachedData[key];
		}
	}

	private setPreviousSessionUser(sessionUser: AuthUserModel): void {
		// TODO this is temporary code Untill we set up a SaaS Admin when an entity is created.
		// They will have a flag that will prevent them from showing up in user lists which we can use to determine if we should clear them our of the Previous user
		if (sessionUser.email && !(sessionUser.email.includes('@inthecode.com.au') && sessionUser.email.includes('super-admin'))) {
			if (sessionUser) {
				if (this.previousSessionUser) {
					this.previousSessionUser.id = sessionUser.id;
					this.previousSessionUser.email = sessionUser.email;
					this.previousSessionUser.firstName = sessionUser.firstName;
					this.previousSessionUser.lastName = sessionUser.lastName;
				} else {
					this.previousSessionUser = {
						email: sessionUser.email,
						firstName: sessionUser.firstName,
						id: sessionUser.id,
						lastName: sessionUser.lastName,
					};
				}

				localStorage.setItem(this.localStorageKeys.previousUser, JSON.stringify(this.previousSessionUser));
			}
		}
	}

	public clearSelectedProject() {
		localStorage.removeItem(this.localStorageKeys.selectedProject)
	}
}
